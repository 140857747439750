export function ParseFloat(str, val) {
  str = str.toString();
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
}

export function arrayGen(number, length) {
  let resultArray = [];
  for (let i = 0; i < length; i++) {
    resultArray.push(number);
  }
  return resultArray;
}

/**
 * Returns an array of incrementally larger numbers.
 *
 * @param {*} start - Beginning value
 * @param {*} end - Stop condition
 * @param {*} step - Step increment
 * @returns An array between the start and end values, incremented by the step amount.
 *
 * @example
 * const result = getIncrementArray(0, 10, 2);
 * console.log(result); // Output: [0, 2, 4, 6, 8, 10]
 */
export function getIncrementArray(start, end, step) {
  let current = start;
  let outArray = [];

  while (current <= end) {
    outArray.push(current);
    current = current + step;
  }
  return outArray;
}
