import "./statistics-single.style.scss";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function StatisticsHistogram({
  graph,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  title = "Title",
  value,
  image,
  row,
  data = [1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3],
  labels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
}) {
  const options = {
    scales: {
      x: {
        title: {
          display: graph.x.title !== "none",
          text: graph.x.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
      },
      y: {
        title: {
          display: graph.y.title !== "none",
          text: graph.y.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const dataset = {
    labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: "rgba(20, 85, 255, 0.5)",
      },
    ],
  };

  console.log(data);

  return (
    <div
      style={{
        gridColumnStart: colStart,
        gridColumnEnd: colEnd,
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
      }}
      className={"single-item statistics-grid-item row-" + row}
    >
      <h1>{title}</h1>
      <Bar options={options} data={dataset} />
    </div>
  );
}
