import { useState } from "react";
import OptionsTab from "../OptionsTab/options-tab.component";
import InputChildren from "../InputChildren/input-children.component";
import InputHeading from "../InputHeading/input-heading.component";
import ShowChildren from "../ShowChildren/show-children.component";

export default function ProgPlotsTab() {
  return (
    <OptionsTab>
      <SurvivalModel />
    </OptionsTab>
  );
}

function SurvivalModel() {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const [foldState, setFoldState] = useState(true);
  const handleFoldChange = () => {
    setFoldState(!foldState);
  };

  return (
    <>
      <InputHeading
        name="survivial-model"
        foldState={foldState}
        handleFoldChange={handleFoldChange}
      >
        Survival model
      </InputHeading>

      <ShowChildren foldState={foldState}>
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--s-mod--e-mod",
            markState: 1,
            label: "Exponential model",
          }}
          inputs={[]}
        />
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--s-mod--w-mod",
            markState: 1,
            label: "Weibull model",
          }}
          inputs={[]}
        />
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--s-mod--n-mod",
            markState: 1,
            label: "Normal model",
          }}
          inputs={[]}
        />
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--s-mod--g-mod",
            markState: 1,
            label: "Gamma model",
          }}
          inputs={[]}
        />
      </ShowChildren>
    </>
  );
}
