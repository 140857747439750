import "./tiny-input.style.scss";
import { useState } from "react";
import parse from "html-react-parser";

export default function TinyInput({
  main,
  name,
  label,
  initialValue,
  onChange,
  min,
  max,
  step,
}) {
  const [textValue, setTextValue] = useState(initialValue);
  const [validationError, setValidationError] = useState(null);

  const handleTextChange = (newValue) => {
    setTextValue(newValue);

    // Call the onChange function with the new value

    if (
      !isNaN(newValue) &&
      !(newValue < min) &&
      !(newValue > max) &&
      onChange
    ) {
      onChange(newValue);
    }
  };

  const handleTinyInputChange = (event) => {
    const newValue = event.target.value;
    handleTextChange(newValue);

    // Validate the input here only if the value is changed and invalid
    if (
      newValue !== textValue && // Check if the value is changed
      (isNaN(newValue) || newValue < min || newValue > max)
    ) {
      setValidationError(
        "Invalid input. Expected: number between " + min + " and " + max + "."
      );
    } else {
      setValidationError(null);
    }
  };

  return (
    <div className={main ? "tiny-input-main" : "tiny-input"}>
      <label htmlFor={name + "-checkbox"}>{parse(label)}</label>
      {validationError && <div className="error">{validationError}</div>}

      <input
        step={step}
        min={min}
        max={max}
        type="number"
        id={name + "-textfield"}
        name={name + "-textfield"}
        value={textValue}
        onChange={handleTinyInputChange}
      />
    </div>
  );
}
