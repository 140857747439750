import { useEffect, useState } from "react";
import OptionsTab from "../OptionsTab/options-tab.component";
import InputChildren from "../InputChildren/input-children.component";
import InputHeading from "../InputHeading/input-heading.component";
import ShowChildren from "../ShowChildren/show-children.component";

export default function GISPipesTab({
  setDataRetrievalState,
  setGisParameters,
  gisParameters,
  setGisNumberInputs,
  gisNumberInputs,
}) {
  return (
    <OptionsTab>
      <GISInputs
        setGisNumberInputs={setGisNumberInputs}
        gisParameters={gisParameters}
        setGisParameters={setGisParameters}
        gisNumberInputs={gisNumberInputs}
      />
    </OptionsTab>
  );
}

function GISInputs({
  gisNumberInputs,
  gisParameters,
  setGisParameters,
  setGisNumberInputs,
}) {
  const [checked, setChecked] = useState(false);

  // const handleCheckboxChange = (name) => {
  //   setGisParameters({
  //     ...gisParameters,
  //     name: !checked,
  //   });
  //   // setChecked(!checked);
  // };

  // const handleCheckboxChange = () => {
  //   setChecked(!checked);
  // };

  const [foldStateParameters, setFoldStateParameters] = useState(true);
  const [foldStateDamages, setFoldStateDamages] = useState(true);
  const [foldStateMaterials, setFoldStateMaterials] = useState(true);

  const handleFoldChangeParameters = () => {
    setFoldStateParameters(!foldStateParameters);
  };

  const handleFoldChangeDamages = () => {
    setFoldStateDamages(!foldStateDamages);
  };

  const handleFoldChangeMaterials = () => {
    setFoldStateMaterials(!foldStateMaterials);
  };

  const [networkParameters, setNetworkParameters] = useState({
    nLevels: gisParameters.nLevels,
    noise: gisParameters.noise,
  });

  const [networkMaterials, setNetworkMaterials] = useState({
    nLevels: gisParameters.nLevels,
    noise: gisParameters.noise,
  });

  return (
    <>
      <InputHeading
        name="gis"
        main={true}
        foldState={foldStateParameters}
        handleFoldChange={handleFoldChangeParameters}
      >
        {" "}
        Network Parameters
      </InputHeading>
      <ShowChildren foldState={foldStateParameters}>
        <InputChildren
          main={true}
          name="gis"
          setData={setGisNumberInputs}
          setGisParameters={setGisParameters}
          gisParameters={gisParameters}
          parent={false}
          tableColumnEqualsTo={[false, false]}
          table={[
            [
              {
                name: "gis-p--n",
                label: "n <i>(levels)</i>",
                initialValue: gisNumberInputs["gis-p--n"],
                min: 1,
                max: 5,
                step: 1,
              },
            ],
            [
              {
                name: "gis-p--noise",
                label: "Noise",
                initialValue: gisNumberInputs["gis-p--noise"],
                min: 0,
                max: 1,
                step: 0.1,
              },
            ],
          ]}
        />
      </ShowChildren>
      <InputHeading
        name="gis-material"
        main={true}
        foldState={foldStateMaterials}
        handleFoldChange={handleFoldChangeMaterials}
      >
        Materials
      </InputHeading>
      <ShowChildren foldState={foldStateMaterials}>
        <InputChildren
          setGisParameters={setGisParameters}
          gisParameters={gisParameters}
          main={true}
          name="gis-material"
          setData={setGisNumberInputs}
          data={gisParameters}
          parent={false}
          inputs={[
            {
              name: "gis-m--pvc",
              label: "PVC",
            },
            {
              name: "gis-m--concrete",
              label: "Concrete",
              markState: true,
            },
          ]}
        />
      </ShowChildren>
      <InputHeading
        name="gis-damage"
        main={true}
        foldState={foldStateDamages}
        handleFoldChange={handleFoldChangeDamages}
      >
        Damages
      </InputHeading>
      <ShowChildren foldState={foldStateDamages}>
        <InputChildren
          setGisParameters={setGisParameters}
          gisParameters={gisParameters}
          main={true}
          name="gis-damage"
          setData={setGisNumberInputs}
          data={gisParameters}
          parent={false}
          inputs={[
            {
              name: "gis-d--surface",
              label: "Surface",
              markState: true,
            },
            {
              name: "gis-d--root",
              label: "Root",
            },
            {
              name: "gis-d--crack",
              label: "Crack",
            },
          ]}
        />
      </ShowChildren>
    </>
  );
}
