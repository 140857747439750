import { useState } from "react";
import OptionsTab from "../OptionsTab/options-tab.component";
import InputChildren from "../InputChildren/input-children.component";
import InputHeading from "../InputHeading/input-heading.component";
import ShowChildren from "../ShowChildren/show-children.component";

export default function ProgAnalysisTab() {
  return (
    <OptionsTab>
      <LinearRegressionModel />
    </OptionsTab>
  );
}

function LinearRegressionModel() {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const [foldState, setFoldState] = useState(true);
  const handleFoldChange = () => {
    setFoldState(!foldState);
  };

  return (
    <>
      <InputHeading
        name="linear-regression-model"
        foldState={foldState}
        handleFoldChange={handleFoldChange}
      >
        Linear regression model
      </InputHeading>

      <ShowChildren foldState={foldState}>
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--l-reg--sl-reg",
            markState: 1,
            label: "Simple linear regression model",
          }}
          inputs={[
            {
              hasField: false,
              name: "p-model--l-reg--sl-reg--formula",
              label: "Formula Y = a + bX + ε",
              markState: { checked },
              onChange: { handleCheckboxChange },
            },
            {
              hasField: true,
              name: "p-model--l-reg--sl-reg--a",
              label: "a (intercept)",
              initialValue: 3,
              markState: { checked },
              onChange: { handleCheckboxChange },
            },
            {
              hasField: true,
              name: "p-model--l-reg--sl-reg--b",
              label: "b (slope)",
              initialValue: 4,
              markState: { checked },
              onChange: { handleCheckboxChange },
            },
            {
              hasField: true,
              name: "p-model--l-reg--sl-reg--epsilon",
              label: "ε (residuals)",
              initialValue: 2,
              markState: { checked },
              onChange: { handleCheckboxChange },
            },
          ]}
        />
        <InputChildren
          parent={{
            hasField: false,
            name: "p-model--l-reg--ml-reg",
            markState: 1,
            label: "Multiple linear regression model",
          }}
          inputs={[]}
        />
      </ShowChildren>
    </>
  );
}
