import plotIcon from "../../assets/icons8-play-40.png";
import exportIcon from "../../assets/icon-download.png";
import { useEffect } from "react";
import InfoButton from "../InfoButton/info-button.component";

export default function InputHeading({
  isLoading,
  main,
  setShowExportDialogue,
  setSelectedExport,
  name,
  children,
  foldState,
  handleFoldChange,
  handleSubmit,
  inputChanged,
  wip,
  infoTitle,
  infoContent,
}) {
  const toggleShowDialogue = () => {
    setShowExportDialogue(true);
    setSelectedExport(name);
  };

  return (
    <div
      className={
        wip
          ? "input-heading input-heading--wip"
          : main
          ? "input-heading-main"
          : "input-heading"
      }>
      <div className="input-row">
        <div
          className={
            foldState ? "folder folder-folded" : "folder folder-unfolded"
          }>
          <input
            type="checkbox"
            name="fold-checkbox"
            id={name + "-fold-checkbox"}
            checked={foldState}
            onChange={handleFoldChange}
          />
          <label htmlFor={name + "-fold-checkbox"}>{children}</label>
         {foldState && <InfoButton title={infoTitle}>{infoContent}</InfoButton>}
          <span className="input-btns">
            <button className="btn-export" onClick={toggleShowDialogue}>
              <img src={exportIcon} />
            </button>

            <button className="btn-plot" onClick={handleSubmit}>
              {isLoading ? (
                <div className="plot-loading"></div>
              ) : (
                <img src={plotIcon} />
              )}
              Plot
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}
