import OptionsHub from "../../components/OptionsHub/options-hub.component";
import ProgModelTab from "../../components/Tabs/p-models-tab.component";
import ProgAnalysisTab from "../../components/Tabs/p-analysis-tab.component";
import ProgPlotsTab from "../../components/Tabs/p-plots-tab.component";
import { useEffect, useState, useRef } from "react";
import MultiGraph from "../../components/MultiGraph/multi-graph.component";
import ContentMenu from "../../components/ContentMenu/content-menu.component";
import UKFGraph from "../../components/UKFGraph/ukf-graph.component";
import iconClose from "../../assets/icons-close.png";
import useSession from "../../hooks/useSession";
import useLocal from "../../hooks/useLocal";
import SLURFGraph from "../../components/SLURFGraph/slurf-graph.component";
import PageTitleCard from "../../components/PageTitleCard/page-title-card.component";
import iconLoading from "../../assets/loading.gif"

export default function Prognostics({specialState, networkGenerated, setNetworkGenerated}) {
  // Set to use a local server instance (change fetch URL)
  const localServer = false;
  // Set server URL and local instance location
  const serverUrl = localServer
    ? "http://127.0.0.1:5000"
    : "https://primavera.codesystems.org";

  const [appliedCD, setAppliedCD] = useState("CMW-BAF");

  const childRef = useRef();

  function calculateArrays(arr1, arr2, arr3, arr4, arr5) {
    const resultArray1 = arr1.slice();
    const resultArray2 = [];
    const resultArray3 = [];
    const resultArray4 = [];
    const resultArray5 = [];

    for (let i = 0; i < arr1.length; i++) {
      resultArray2.push(arr1[i] + arr2[i]);
      resultArray3.push(resultArray2[i] + arr3[i]);
      resultArray4.push(resultArray3[i] + arr4[i]);
      resultArray5.push(resultArray4[i] + arr5[i]);
    }

    return [
      resultArray1,
      resultArray2,
      resultArray3,
      resultArray4,
      resultArray5,
    ];
  }

  // Cohorts
  const [cohortString, setCohortString] = useSession("p--cohort-string", "CMW");

  const handleCohortChange = (event) => {
    const newValue = event.target.value;
    setCohortString(newValue);
  };

  // Damages
  const [damageString, setDamageString] = useSession("p--damage-string", "BAF");

  const handleDamageChange = (event) => {
    const newValue = event.target.value;
    setDamageString(newValue);
  };

  // Cohorts and damages

  const getDefaultCohortDamageString = () => {
    return cohortString + "-" + damageString;
  };

  const [cohortDamageString, setCohortDamageString] = useState(
    getDefaultCohortDamageString()
  );

  useEffect(() => {
    setCohortDamageString(cohortString + "-" + damageString);
  }, [cohortString, damageString]);

  const updateGraphData = (graphKey, newState) => {
    setGraphData((prevGraphData) => ({
      ...prevGraphData,
      [graphKey]: {
        ...prevGraphData[graphKey],
        ...newState,
      },
    }));
  };

  const [currentTab, setCurrentTab] = useState("models");

  const [graphData, setGraphData] = useState({
    dtmcOne: {
      mean: [],
      upper: [],
      lower: [],
    },
    dtmcTwo: {
      mean: [],
      upper: [],
      lower: [],
    },
    dtmcThree: {
      mean: [],
      upper: [],
      lower: [],
    },
    dtmcFour: {
      mean: [],
      upper: [],
      lower: [],
    },
    dtmcFive: {
      mean: [],
      upper: [],
      lower: [],
    },
    dtmcSix: {
      one: [],
      two: [],
      three: [],
      four: [],
      five: [],
    },
    ukf: {
      inspectionsX: [],
      inspectionsY: [],
      filteringStageY: [],
      Inspection_number: null,
      predictedMeanX: [],
      predictedMeanY: [],
      predictedLowX: [],
      predictedLowY: [],
      predictedUpX: [],
      predictedUpY: [],
      time: [],
      timeMean: [],
      crackLengths: [],
      yThreshold: null,
      timeInspection: [],
      dsPrep: [],
      toggleOptional: null,
    },
    slurf: {
      slurfData: {},
    },
  });

  useEffect(() => {
    const [
      resultArray1,
      resultArray2,
      resultArray3,
      resultArray4,
      resultArray5,
    ] = calculateArrays(
      graphData.dtmcOne.mean,
      graphData.dtmcTwo.mean,
      graphData.dtmcThree.mean,
      graphData.dtmcFour.mean,
      graphData.dtmcFive.mean
    );
    updateGraphData("dtmcSix", {
      one: resultArray1,
      two: resultArray2,
      three: resultArray3,
      four: resultArray4,
      five: resultArray5,
    });
  }, []);

  const getGraphView = () => {
    const defaultState = "focused";
    const retrievedState = sessionStorage.getItem("p--graph-view");
    return retrievedState != null ? retrievedState : defaultState;
  };

  const [graphView, setGraphView] = useState(getGraphView());

  const saveGraphView = () => {
    sessionStorage.setItem("p--graph-view", graphView.toString());
  };

  useEffect(() => {
    saveGraphView();
  }, [graphView]);

  const getFocusGraph = () => {
    const defaultState = "graph-one";
    const retrievedState = sessionStorage.getItem("p--focus-graph");
    return retrievedState != null ? retrievedState : defaultState;
  };

  const [focusGraph, setFocusGraph] = useState(getFocusGraph());

  const saveFocusGraph = () => {
    sessionStorage.setItem("p--focus-graph", focusGraph.toString());
  };

  useEffect(() => {
    saveFocusGraph();
  }, [focusGraph]);

  const [graphRangeDTMC, setGraphRangeDTMC] = useState(100);
  const [graphRangeUKF, setGraphRangeUKF] = useState(125);

  

  const [foldStateDTMC, setFoldStateDTMC] = useLocal("fold-state-dtmc", true);
  const [foldStateUKF, setFoldStateUKF] = useLocal("fold-state-ukf", false);
  const [foldStateSLURF, setFoldStateSLURF] = useLocal(
    "fold-state-slurf",
    false
  );

  useEffect(() => {
    if (sessionStorage['gis--table-data'] != undefined || sessionStorage['gis--table-data'] != null) {
      setNetworkGenerated(true);
    } else {
      setNetworkGenerated(false);
    }
  }, [sessionStorage.getItem('gis--table-data') ]);

  useEffect(() => {
    if (foldStateDTMC) {
      setFoldStateSLURF(false);
      setFoldStateUKF(false);
    }
  }, [foldStateDTMC]);

  useEffect(() => {
    if (foldStateUKF) {
      setFoldStateSLURF(false);
      setFoldStateDTMC(false);
    }
  }, [foldStateUKF]);

  useEffect(() => {
    if (foldStateSLURF) {
      setFoldStateUKF(false);
      setFoldStateDTMC(false);
    }
  }, [foldStateSLURF]);

  const handleFocusChange = (event) => {
    setFocusGraph(event.target.value);
  };
  const handleViewChange = (view) => {
    setGraphView(view);
    setFocusGraph("graph-one");
  };

  const handleDTMCFetch = () => {
    childRef.current.handleRetrieveDTMC();
    setAppliedCD(cohortDamageString);
  };

     // Session storage : Set default (on load) DTMC parameters
     const getDTMCData = () => {
      // Parameters upon initial page load
      const defaultState = {
        "p-dtmc--k1-2": 0.06398620253997074,
        "p-dtmc--k2-3": 0.03616654868845995,
        "p-dtmc--k3-4": 0.03630620793807168,
        "p-dtmc--k4-5": 0.11906722358542816,
        "p-dtmc--s1": 0.9960201408472367,
        "p-dtmc--s2": 0.0005783285820680725,
        "p-dtmc--s3": 0.0019464582422561414,
        "p-dtmc--s4": 0.0014508531974238151,
        "p-dtmc--s5": 0.000004219131015523918,
        "p-dtmc--range": "100",
      };
      // Parameters retrieved from sessionStorage
      const retrievedState = JSON.parse(sessionStorage.getItem("p-dtmc"));

      return retrievedState != null ? retrievedState : defaultState;
    };

    const [DTMCData, setDTMCData] = useState(getDTMCData());

    // Upon DTMC parameter change, update session storage key value
    useEffect(() => {
      sessionStorage.setItem("p-dtmc", JSON.stringify(DTMCData));
      

    }, [DTMCData]);

    const [DTMCFreq, setDTMCFreq] = useState([{},{},{},{},{}]);

    const handleSubmitDTMC = (otherdata = null, doUpdate = true) => {
      // Ignore event objects
      if (otherdata && otherdata.nativeEvent) {
        otherdata = null;
      }
      if (sessionStorage['gis--table-data'] != undefined && doUpdate) {
        //let data = otherdata != null ? otherdata : DTMCData;
        
        if (otherdata != null) {
          handleFreqData(otherdata);
        } else {
          handleFreqData(DTMCData);
        }
      }
      

      // Return a promise
      return new Promise((resolve, reject) => {
        // console.log('DATA', otherdata);
        setGraphRangeDTMC(DTMCData["p-dtmc--range"]);
        setIsLoadingDTMC(true);
        // Make the fetch request using the DTMC parameters
        console.log('SEND TO SERVER', DTMCData);
        fetch("https://primavera.codesystems.org" + "/p-dtmc/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: otherdata != null ? 
          JSON.stringify({
            otherdata, doUpdate
          }) : JSON.stringify({
            DTMCData, doUpdate
          }),
        })
          .then((response) => {
            otherdata != null ? console.log('Sent: ', otherdata) : console.log('Sent: ', DTMCData);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Handle the data received from the server
            const [
              resultArray1,
              resultArray2,
              resultArray3,
              resultArray4,
              resultArray5,
            ] = calculateArrays(
              data.S_1_mean,
              data.S_2_mean,
              data.S_3_mean,
              data.S_4_mean,
              data.S_5_mean
            );
    
            updateGraphData("dtmcOne", {
              mean: data.S_1_mean,
              upper: data.S_1_up,
              lower: data.S_1_lower,
            });
            updateGraphData("dtmcTwo", {
              mean: data.S_2_mean,
              upper: data.S_2_up,
              lower: data.S_2_lower,
            });
            updateGraphData("dtmcThree", {
              mean: data.S_3_mean,
              upper: data.S_3_up,
              lower: data.S_3_lower,
            });
            updateGraphData("dtmcFour", {
              mean: data.S_4_mean,
              upper: data.S_4_up,
              lower: data.S_4_lower,
            });
            updateGraphData("dtmcFive", {
              mean: data.S_5_mean,
              upper: data.S_5_up,
              lower: data.S_5_lower,
            });
            updateGraphData("dtmcSix", {
              one: resultArray1,
              two: resultArray2,
              three: resultArray3,
              four: resultArray4,
              five: resultArray5,
            });
            resolve(data); // Resolve the promise with the data
          })
          .finally(() => {
            setIsLoadingDTMC(false);
          })
          .catch((error) => {
            // Reject the promise with the error
            reject(error);
            // Handle any errors that occur during the request
            console.error("Error:", error);
          });
      });
    };
    

    
  const [isLoadingDTMC, setIsLoadingDTMC] = useState(false);

  const [isLoadingFit, setIsLoadingFit] = useState(false);

  const handleFitNow = () => {
    setIsLoadingFit(true);

    // Return a promise
    return new Promise((resolve, reject) => {
      // Make the fetch request with the cohort and damage parameters
      fetch("https://primavera.codesystems.org" + "/dtmc-fit-now/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ FNData: JSON.stringify(DTMCData)
         }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          //setDTMCData(data);
          resolve(data); // Resolve the promise with the data
          console.log('Received: ', data);
          return data;
        })
        .then((data) => {
          console.log('CORRECT FIT NOW PARAMS', data);
          handleSubmitDTMC(data, false);
        })
        .finally(() => {
          setIsLoadingFit(false);
        })
        .catch((error) => {
          // Reject the promise with the error
          reject(error);
          // Handle any errors that occur during the request
          console.error("Error:", error);
        });
    });
  };


  const handleDTMCFit = (otherdata = null, doUpdate = true) => {
    // Ignore event objects
    if (otherdata && otherdata.nativeEvent) {
      otherdata = null;
    }
    if (sessionStorage['gis--table-data'] != undefined && doUpdate) {
      //let data = otherdata != null ? otherdata : DTMCData;
      
      if (otherdata != null) {
        handleFreqData(otherdata);
      } else {
        handleFreqData(DTMCData);
      }
    }
    

    // Return a promise
    return new Promise((resolve, reject) => {
      // console.log('DATA', otherdata);
      setGraphRangeDTMC(DTMCData["p-dtmc--range"]);
      setIsLoadingFit(true);
      // Make the fetch request using the DTMC parameters
      console.log('SEND TO SERVER', DTMCData);
      fetch("https://primavera.codesystems.org" + "/p-dtmc-fit/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: otherdata != null ? 
        JSON.stringify({
          otherdata, doUpdate
        }) : JSON.stringify({
          DTMCData, doUpdate
        }),
      })
        .then((response) => {
          otherdata != null ? console.log('Sent: ', otherdata) : console.log('Sent: ', DTMCData);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle the data received from the server
          const [
            resultArray1,
            resultArray2,
            resultArray3,
            resultArray4,
            resultArray5,
          ] = calculateArrays(
            data.S_1_mean,
            data.S_2_mean,
            data.S_3_mean,
            data.S_4_mean,
            data.S_5_mean
          );
  
          updateGraphData("dtmcOne", {
            mean: data.S_1_mean,
            upper: data.S_1_up,
            lower: data.S_1_lower,
          });
          updateGraphData("dtmcTwo", {
            mean: data.S_2_mean,
            upper: data.S_2_up,
            lower: data.S_2_lower,
          });
          updateGraphData("dtmcThree", {
            mean: data.S_3_mean,
            upper: data.S_3_up,
            lower: data.S_3_lower,
          });
          updateGraphData("dtmcFour", {
            mean: data.S_4_mean,
            upper: data.S_4_up,
            lower: data.S_4_lower,
          });
          updateGraphData("dtmcFive", {
            mean: data.S_5_mean,
            upper: data.S_5_up,
            lower: data.S_5_lower,
          });
          updateGraphData("dtmcSix", {
            one: resultArray1,
            two: resultArray2,
            three: resultArray3,
            four: resultArray4,
            five: resultArray5,
          });
          resolve(data); // Resolve the promise with the data
        })
        .finally(() => {
          setIsLoadingFit(false);

        })
        .catch((error) => {
          // Reject the promise with the error
          reject(error);
          // Handle any errors that occur during the request
          console.error("Error:", error);
        });
    });
  };
  

  

  const handleFreqData = (freqData = null) => {


    // Return a promise
    return new Promise((resolve, reject) => {
      // Make the fetch request with the cohort and damage parameters
      fetch("https://primavera.codesystems.org" + "/dtmc-freq-data/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ FNData: freqData 
         }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          //setDTMCData(data);
          resolve(data); // Resolve the promise with the data
          console.log('Received: ', data);
          return data;
        })
        .then((data) => {
          setDTMCFreq(data);
          //handleSubmitDTMC(data);
        })
        .finally(() => {
        })
        .catch((error) => {
          // Reject the promise with the error
          reject(error);
          // Handle any errors that occur during the request
          console.error("Error:", error);
        });
    });
  };

  

  return (
    <>
      <div className="content prognostics-content">
        <PageTitleCard showInfoButton={false} title="Prognostics">
          This module introduces three algorithms: <strong>DTMC</strong>,{" "}
          <strong>UKF</strong>, and <strong>SLURF</strong>. Dive in to
          experiment with the <strong>hyperparameters</strong> of each algorithm
          and witness the wonders of <strong>prognostics</strong> in action. For
          your convenience, we've included{" "}
          <strong>informative pop-up boxes</strong> throughout. Simply click on
          these to reveal detailed descriptions of the various elements
          displayed, <strong>enhancing your understanding</strong> of the
          results. This <strong>interactive feature</strong> ensures a more
          engaging and informative experience as you explore the capabilities of
          each prognostic model.
        </PageTitleCard>
        <div style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
          <OptionsHub>
            {foldStateDTMC && (
              <div className="options-search">
                <div className="inputs-container">
                  <span>Cohort: </span>
                  <select
                    value={cohortString}
                    onChange={handleCohortChange}
                    id="cohort-selector"
                  >
                    <option value="CMW">CMW</option>
                    <option value="CR">CR</option>
                    <option value="PMW">PMW</option>
                    <option value="PR">PR</option>
                    <option value="CdL">CdL</option>
                    <option value="CdG">CdG</option>
                  </select>
                  <span>Damage: </span>
                  <select
                    value={damageString}
                    onChange={handleDamageChange}
                    id="damage-selector"
                  >
                    <option value="BAF">BAF</option>
                    <option value="BAB">BAB</option>
                    <option value="BBF">BBF</option>
                  </select>
                </div>
                <button onClick={handleDTMCFetch} className="btn-apply">
                  Apply
                </button>
              </div>
            )}
            <div
              className="cohort-damage-container"
              style={{ display: "none" }}
            >
              <div className="cohort-damage-tag">
                {appliedCD}
                <img src={iconClose} />
              </div>
            </div>

            {currentTab === "models" && (
              <ProgModelTab
              specialState={specialState}
              handleSubmitDTMC={handleSubmitDTMC}
              setDTMCData={setDTMCData}
              DTMCData={DTMCData}
                ref={childRef}
                cohortDamageString={cohortDamageString}
                setFoldStateDTMC={setFoldStateDTMC}
                foldStateDTMC={foldStateDTMC}
                setFoldStateUKF={setFoldStateUKF}
                foldStateUKF={foldStateUKF}
                setGraphRangeDTMC={setGraphRangeDTMC}
                updateGraphData={updateGraphData}
                graphData={graphData}
                foldStateSLURF={foldStateSLURF}
                setFoldStateSLURF={setFoldStateSLURF}
                isLoadingDTMC={isLoadingDTMC}
                setIsLoadingDTMC={setIsLoadingDTMC}
                handleFreqData={handleFreqData}
                networkGenerated={networkGenerated}
              />
            )}
            {currentTab === "plots" && <ProgPlotsTab />}
            {currentTab === "analysis" && <ProgAnalysisTab />}
            <div className="options-footer">
              {foldStateDTMC && <button onClick={handleDTMCFit} className={specialState || networkGenerated ? (isLoadingFit ? "btn-fit fit-btn-generated fit-anim" : "btn-fit fit-btn-generated fit-no-anim") : "btn-fit fit-btn-no-gen fit-no-anim"}>
                {specialState || networkGenerated ? (isLoadingFit ? <img src={iconLoading}/> : "Fit now") : "Fit now"}
                {/* {(!networkGenerated || !specialState) && <div style={{fontSize: "10px"}}>Generate a network first</div>} */}
              </button>}
            </div>
          </OptionsHub>
          <div className="content-graphs">
            <ContentMenu
              focusGraph={focusGraph}
              foldStateDTMC={foldStateDTMC}
              foldStateUKF={foldStateUKF}
              graphView={graphView}
              handleViewChange={handleViewChange}
              handleFocusChange={handleFocusChange}
            />

            {foldStateDTMC && (
              <div
                className={
                  (graphView === "single" && "single-graph-grid") ||
                  (graphView === "all" && "graph-grid") ||
                  (graphView === "focused" && "focused-graph-grid")
                }
              >
                {graphView === "all" ||
                focusGraph === "graph-one" ||
                graphView === "single" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="single"
                        graph={{
                          label: "State probability over pipe age with k = 1",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Mean",
                            data: graphData.dtmcOne.mean,
                            borderColor: "rgba(0, 112, 255, 255)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                          },
                          {
                            label: "Lower bound",
                            data: graphData.dtmcOne.lower,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          {
                            label: "Upper bound",
                            data: graphData.dtmcOne.upper,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(0, 0, 255, 0.2)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          DTMCFreq[0] ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {graphView === "all" ||
                focusGraph === "graph-two" ||
                graphView === "single" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="single"
                        graph={{
                          label: "State probability over pipe age with k = 2",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Mean",
                            data: graphData.dtmcTwo.mean,
                            borderColor: "rgba(0, 112, 255, 255)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                          },
                          {
                            label: "Lower bound",
                            data: graphData.dtmcTwo.lower,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          {
                            label: "Upper bound",
                            data: graphData.dtmcTwo.upper,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(0, 0, 255, 0.2)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          DTMCFreq[1] ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {graphView === "all" ||
                focusGraph === "graph-three" ||
                graphView === "single" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="single"
                        graph={{
                          label: "State probability over pipe age with k = 3",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Mean",
                            data: graphData.dtmcThree.mean,
                            borderColor: "rgba(0, 112, 255, 255)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                          },
                          {
                            label: "Lower bound",
                            data: graphData.dtmcThree.lower,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          {
                            label: "Upper bound",
                            data: graphData.dtmcThree.upper,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(0, 0, 255, 0.2)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          DTMCFreq[2] ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {graphView === "all" ||
                focusGraph === "graph-four" ||
                graphView === "single" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="single"
                        graph={{
                          label: "State probability over pipe age with k = 4",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Mean",
                            data: graphData.dtmcFour.mean,
                            borderColor: "rgba(0, 112, 255, 255)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                          },
                          {
                            label: "Lower bound",
                            data: graphData.dtmcFour.lower,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          {
                            label: "Upper bound",
                            data: graphData.dtmcFour.upper,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(0, 0, 255, 0.2)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          DTMCFreq[3]]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {graphView === "all" ||
                focusGraph === "graph-five" ||
                graphView === "single" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="single"
                        graph={{
                          label: "State probability over pipe age with k = 5",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Mean",
                            data: graphData.dtmcFive.mean,
                            borderColor: "rgba(0, 112, 255, 255)",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                          },
                          {
                            label: "Lower bound",
                            data: graphData.dtmcFive.lower,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          {
                            label: "Upper bound",
                            data: graphData.dtmcFive.upper,
                            borderColor: "rgba(0, 112, 255, 100)",
                            backgroundColor: "rgba(0, 0, 255, 0.2)",
                            borderDash: [6, 2],
                            borderWidth: 1.5,
                          },
                          DTMCFreq[4]]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {graphView === "all" || graphView === "focused" ? (
                  <>
                    <div className="line-graph">
                      <MultiGraph
                        type="multi"
                        graph={{
                          label:
                            "Combine graph of state probability over pipe age with k = 1,2,3,4,5",
                          x: {
                            title: "Pipe age",
                            min: 0,
                            max: graphRangeDTMC,
                            step: 10,
                          },
                          y: {
                            title: "State probability",
                            min: 0,
                            max: 1,
                            step: 0.2,
                          },
                        }}
                        dataArray={[
                          {
                            label: "Severity 1",
                            data: graphData.dtmcSix.one,
                            borderColor: "rgb(30 90 26)",
                            backgroundColor: "rgb(180 211 178)",
                          },
                          {
                            label: "Severity 2",
                            data: graphData.dtmcSix.two,
                            borderColor: "rgb(0, 112, 255)",
                            backgroundColor: "rgb(154 198 255)",
                          },
                          {
                            label: "Severity 3",
                            data: graphData.dtmcSix.three,
                            borderColor: "rgb(225 166 57)",
                            backgroundColor: "rgb(247 255 147)",
                          },
                          {
                            label: "Severity 4",
                            data: graphData.dtmcSix.four,
                            borderColor: "rgb(225 57 57)",
                            backgroundColor: "rgb(255 199 147)",
                          },
                          {
                            label: "Severity 5",
                            data: graphData.dtmcSix.five,
                            borderColor: "rgb(255 147 147)",
                            backgroundColor: "rgb(255 147 147)",
                          },
                        ]}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            {foldStateUKF && (
              <div
                className={
                  (graphView === "single" && "single-graph-grid") ||
                  (graphView === "all" && "graph-grid") ||
                  (graphView === "focused" && "focused-graph-grid")
                }
              >
                <div className="line-graph">
                  <UKFGraph
                    graph={{
                      label: [
                        "Crack growth prediction after " +
                          graphData.ukf.timeInspection +
                          " stress cycles ",
                        "and future stress range amplitude " +
                          graphData.ukf.dsPrep +
                          " Mpa",
                      ],
                      x: {
                        title: "Time (stress cycles)",
                        min: 0,
                      },
                      y: {
                        title: "Crack length (mm)",
                        min: 0,
                        max: parseInt(graphData.ukf.yThreshold),
                        step: 1,
                      },
                    }}
                    data={graphData.ukf}
                  />
                </div>
              </div>
            )}
            {foldStateSLURF && (
              <div
                className={
                  (graphView === "single" && "single-graph-grid") ||
                  (graphView === "all" && "graph-grid") ||
                  (graphView === "focused" && "focused-graph-grid")
                }
              >
                <div className="line-graph line-graph-slurf">
                  <SLURFGraph
                    graph={{
                      label: [
                        "Predictions (with uncertainty quantification)",
                        " for the pipe failure probability over time",
                      ],
                      x: {
                        title: "Time",
                        min: 0,
                        min: 10,
                        step: 1,
                      },
                      y: {
                        title: "Pipe failure probability",
                        min: 0,
                        max: 7,
                        step: 1,
                      },
                    }}
                    data={graphData.slurf.slurfData}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
