import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import "./Style.scss";
import "./Layout.scss";
import Home from "./pages/Home/home.page";
import GIS from "./pages/GIS/gis.page";
import Statistics from "./pages/Statistics/statistics.page";
import Prognostics from "./pages/Prognostics/prognostics.page";
import SideBar from "./components/SideBar/side-bar.component";
import { useEffect, useState } from "react";
import ContextMenu from './components/ContextMenu/context-menu.component'
import { useRef } from 'react';

function App() {
  // Set to use a local server instance (change fetch URL)
  const localServer = false;
  // Set server URL and local instance location
  const serverUrl = localServer
    ? "http://127.0.0.1:5000"
    : "https://primavera.codesystems.org";


  const targetRef = useRef(null);
  // const getDefaultDarkMode = () => {
  // const defaultState = false;
  // const savedState = localStorage.getItem("darkMode");

  // return savedState == undefined ? defaultState : savedState;
  // };

  // const [darkMode, setDarkMode] = useState(getDefaultDarkMode());

  const [darkMode, setDarkMode] = useState(false);

  const getDefaultMinimizedSideBar = () => {
    const defaultState = false;
    const savedState = localStorage.getItem("minimized-side-bar");
    return savedState != null
      ? savedState === "true"
        ? true
        : false
      : defaultState;
  };

  const [minimizeSideBar, setMinimizeSideBar] = useState(
    getDefaultMinimizedSideBar()
  );

  useEffect(() => {
    localStorage.setItem("minimized-side-bar", minimizeSideBar.toString());
  }, [minimizeSideBar]);

  useEffect(() => {
    if (darkMode) {
      document.body.style.backgroundColor = "#232632";
    } else {
      document.body.style.backgroundColor = "white";
    }
  }, [darkMode]);

  const menuItems = [
    { label: 'Generate network', onClick: () => {
      setGisNumberInputs({
        "gis-p--n": 1,
        "gis-p--noise": 0.5,
      });
      console.log(gisParameters);
      handleGenerateNetwork();
    } },
    { label: 'Clear network', onClick: () => {
      sessionStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } },
  ];

  const [specialState, setSpecialState] = useState(false);

  const getTableData = () => {
    const defaultState = {
      pipes: [],
      inspections: [],
    };

    const retrievalState = JSON.parse(
      sessionStorage.getItem("gis--table-data")
    );
    return retrievalState != null ? retrievalState : defaultState;
  };

  const [gisNumberInputs, setGisNumberInputs] = useState({
    "gis-p--n": 4,
    "gis-p--noise": 0.5,
  });

  const [tableData, setTableData] = useState(getTableData());

  const handleGenerateNetwork = () => {

    setDataRetrievalState("loading");

    fetch("https://primavera.codesystems.org" + "/gis-pipes/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gisParameters,

      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTableData({
          ...tableData,
          pipes: data.pipes,
          inspections: data.inspections,
        });
        setSpecialState(true);
      })
      .finally(() => {
        setDataRetrievalState("complete");
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const getDataRetrievalState = () => {
    const retrievalState = JSON.parse(
      sessionStorage.getItem("gis--table-data")
    );

    return retrievalState != null ? "complete" : "idle";
  };

  // retrievalState: idle | loading | completed
  const [dataRetrievalState, setDataRetrievalState] = useState(
    getDataRetrievalState()
  );

  const getGISData = () => {
    const defaultState = {
      "gis-p--n": gisNumberInputs["gis-p--n"],
      "gis-p--noise": gisNumberInputs["gis-p--noise"],
      "gis-m--pvc": false,
      "gis-m--concrete": true,
      "gis-d--surface": true,
      "gis-d--root": false,
      "gis-d--crack": false,
    };

    const retrievalState = JSON.parse(sessionStorage.getItem("gis-md"));

    return retrievalState != null ? retrievalState : defaultState;
  };

  const [gisParameters, setGisParameters] = useState(getGISData());

  useEffect(() => {
    if (tableData.pipes.length > 0) {
      sessionStorage.setItem("gis--table-data", JSON.stringify(tableData));
      console.log("updated tableData");
    }
  }, [tableData]);

  const [networkGenerated, setNetworkGenerated] = useState(false);

  return (
    <BrowserRouter>
      <div className="app">
      <ContextMenu menuItems={menuItems}  targetRef={targetRef} />
        <section className={darkMode ? "side side-dark" : "side"}>
          <SideBar
          dataRetrievalState={dataRetrievalState}
            ref={targetRef}
            darkMode={darkMode}
            setDarkMode={setDarkMode}
            minimizeSideBar={minimizeSideBar}
            setMinimizeSideBar={setMinimizeSideBar}
          />
        </section>
        <section className={darkMode ? "main main-dark" : "main"}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gis-map" element={<GIS gisNumberInputs={gisNumberInputs} setGisNumberInputs={setGisNumberInputs} gisParameters={gisParameters} setGisParameters={setGisParameters} handleGenerateNetwork={handleGenerateNetwork} tableData={tableData} setTableData={setTableData} dataRetrievalState={dataRetrievalState} setDataRetrievalState={setDataRetrievalState}/>} />
            <Route path="/gis-statistics" element={<Statistics specialState={specialState}/>} />
            <Route path="/prognostics" element={<Prognostics specialState={specialState} networkGenerated={networkGenerated} setNetworkGenerated={setNetworkGenerated}/>} />
          </Routes>
        </section>
      </div>
    </BrowserRouter>
  );
}

export default App;
