import "./side-bar.style.scss";
import { Link, useLocation } from "react-router-dom";
import primaveraLogo from "../../assets/primavera-logo.png";
import iconHome from "../../assets/icons8-home-24.png";
import iconGIS from "../../assets/icons8-map-marker-24.png";
import iconProg from "../../assets/icons8-plot-24.png";
import iconStat from "../../assets/icons8-bar-chart-24.png";
import iconGear from "../../assets/icons8-gear-96.png";
import { useEffect, useState, forwardRef } from "react";
import SettingsWindow from "../SettingsWindow/settings-window.component";
import iconLoading from "../../assets/icons8-loading-circle.gif"

const SideBar = forwardRef(({
  minimizeSideBar,
  setMinimizeSideBar,
  darkMode,
  setDarkMode,
  dataRetrievalState
}, ref) => {
  const pageLocation = useLocation().pathname;
  const [showSettings, setShowSettings] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (windowSize.innerWidth < 1180 && minimizeSideBar != true) {
      setMinimizeSideBar(true);
    } else if (windowSize.innerWidth >= 1180 && minimizeSideBar != false) {
      setMinimizeSideBar(false);
    }
  }, [windowSize.innerWidth]);

  return (
    <div className={!minimizeSideBar ? "side-bar" : "minimized-side-bar"}>
      {showSettings && (
        <SettingsWindow
          darkMode={darkMode}
          setDarkMode={setDarkMode}
          showSettings={showSettings}
          setShowSettings={setShowSettings}
          minimizeSideBar={minimizeSideBar}
          setMinimizeSideBar={setMinimizeSideBar}
        />
      )}
      <div className="top">
        <header
          className="logo"
          onClick={() => {
            setMinimizeSideBar(!minimizeSideBar);
          }}
        >
          {!minimizeSideBar ? (
            <>
              <img src={primaveraLogo} />
              <div className="heading">PrimaVera</div>
            </>
          ) : (
            <span className="minimized-primavera"></span>
          )}
        </header>
        <Link className={pageLocation === "/" && "side-bar-selected"} to="/">
          <img width="20px" height="20px" src={iconHome} />
          <span className="side-bar-content">Home</span>
        </Link>
        <Link  ref={ref} className={pageLocation === "/gis-map" ? "side-bar-selected gis-link" : "gis-link"} to="/gis-map">
          <img width="20px" height="20px" src={dataRetrievalState === "loading" ? iconLoading : iconGIS} />
          <span className="side-bar-content">GIS Map</span>
        </Link>
        <Link className={pageLocation === "/gis-statistics" && "side-bar-selected"} to="/gis-statistics">
          <img width="20px" height="20px" src={iconStat} />
          <span className="side-bar-content">GIS Statistics</span>
        </Link>
        <Link className={pageLocation === "/prognostics" && "side-bar-selected"} to="/prognostics">
          <img width="20px" height="20px" src={iconProg} />
          <span className="side-bar-content">Prognostics</span>
        </Link>
      </div>
      <div className="bottom">
        <a
          onClick={() => {
            setShowSettings(!showSettings);
          }}
          style={{ cursor: "pointer" }}
          className={pageLocation === "settings" && "side-bar-selected"}
        >
          {!minimizeSideBar ? (
            <u>Settings</u>
          ) : (
            <img width="23px" height="23px" src={iconGear} />
          )}
        </a>
      </div>
    </div>
  );
});

export default SideBar;
