import BaseInput from "../BaseInput/base-input.component";
import "../BaseInput/base-input.style.scss";
import FlatInput from "../FlatInput/flat-input.component";
import TinyInput from "../TinyInput/tiny-input.component";
import "./input-children.styles.scss";
import { useState, useEffect } from "react";

export default function InputChildren({
  main, //no indentation of inputs (part of main heading)
  name,
  setData,
  data,
  inputs,
  table,
  parent,
  setGisParameters,
  gisParameters,
  tableColumnEqualsTo,
  flat,
}) {
  function ParseFloat(str, val) {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  }

  const [inputValues, setInputValues] = useState({});

  const [localData, setLocalData] = useState({});

  function getDTMC(key) {
    return parseFloat(localData[key]);
  }

  const [sValuesError, setSValuesError] = useState(false);
  const [sValuesSum, setSValuesSum] = useState(1);

  // Handle input changes for text inputs
  const handleInputChange = (name, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Check that all S values add to 1
  useEffect(() => {
    setSValuesSum(
      (
        getDTMC("p-dtmc--s1") +
        getDTMC("p-dtmc--s2") +
        getDTMC("p-dtmc--s3") +
        getDTMC("p-dtmc--s4") +
        getDTMC("p-dtmc--s5")
      ).toFixed(2)
    );
    if (
      (
        getDTMC("p-dtmc--s1") +
        getDTMC("p-dtmc--s2") +
        getDTMC("p-dtmc--s3") +
        getDTMC("p-dtmc--s4") +
        getDTMC("p-dtmc--s5")
      ).toFixed(2) != 1 &&
      !isNaN(
        getDTMC("p-dtmc--s1") +
          getDTMC("p-dtmc--s2") +
          getDTMC("p-dtmc--s3") +
          getDTMC("p-dtmc--s4") +
          getDTMC("p-dtmc--s5")
      )
    ) {
      console.log(
        "S ERROR for",
        getDTMC("p-dtmc--s1") +
          getDTMC("p-dtmc--s2") +
          getDTMC("p-dtmc--s3") +
          getDTMC("p-dtmc--s4") +
          getDTMC("p-dtmc--s5")
      );
      setSValuesError(true);
    } else {
      setSValuesError(false);
    }
  }, [localData]);

  useEffect(() => {
    // Combine all input values into a single JSON object
    let combinedData = {};

    if (table == null && flat == null) {
      combinedData = inputs.reduce((result, input) => {
        result[input.name] =
          inputValues[input.name] || input.initialValue || "";
        return result;
      }, {});
    } else {
      if (table) {
        combinedData = table.reduce((result, innerInputs) => {
          innerInputs.forEach((input) => {
            result[input.name] =
              inputValues[input.name] || input.initialValue || "";
          });
          return result;
        }, {});
      }
      if (flat) {
        combinedData = flat.reduce((result, input) => {
          result[input.name] =
            inputValues[input.name] || input.initialValue || "";
          return result;
        }, {});
      }
    }

    // Call setData with the combined data to pass it to the parent component
    setData(combinedData);
    setLocalData(combinedData);
  }, [inputValues]);

  return (
    <>
      <section
        className={sValuesError ? "input-section s-error" : "input-section"}
      >
        {sValuesError ? (
          <span className="s-error-note">
            Error: S values not equal to 1{" "}
            <span className="s-error-number">
              currently {ParseFloat(sValuesSum, 2)}
            </span>
          </span>
        ) : (
          <></>
        )}
        {parent === false ? (
          <></>
        ) : (
          <div className="input-parent">
            <div className="input-row">
              <BaseInput main={main} {...parent} />
            </div>
          </div>
        )}
        {inputs != null ? (
          // Regular input list
          <div className="input-container">
            {inputs.map((input, key) => (
              <div className="input-row">
                <BaseInput
                  setGisParameters={setGisParameters}
                  gisParameters={gisParameters}
                  main={main}
                  {...input}
                  key={key}
                />
              </div>
            ))}
          </div>
        ) : (
          table != null && (
            <div className="table-input-container">
              {table.map((row, rowIndex) => {
                // console.log("Row", row, "RowIndex", rowIndex, "\n");
                let totalSumEqualsTo = tableColumnEqualsTo[rowIndex];
                if (totalSumEqualsTo) {
                }
                return (
                  <div key={rowIndex} className="table-input-parent">
                    {row.map((input, key) => (
                      <div
                        key={key}
                        className={`input-row ${
                          // Target last item of row and column
                          key === row.length - 1 ? "last-row" : ""
                        }`}
                      >
                        <TinyInput
                          main={main}
                          {...input}
                          key={key}
                          onChange={(value) =>
                            handleInputChange(input.name, value)
                          }
                        />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          )
          // Table input list
        )}
        {flat != null ? (
          <>
            <div className="input-container">
              {flat.map((input, key) => (
                <div key={key} className="input-row">
                  <FlatInput
                    main={main}
                    {...input}
                    key={key}
                    onChange={(value) => handleInputChange(input.name, value)}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}
