import ToggleSwitch from "../ToggleSwitch/toggle-switch.component";
import "./settings-window.style.scss";
import iconClose from "../../assets/icons-close.png";
import TigerButton from "../TigerButton/tiger-button.component";
import { useEffect, useState } from "react";

export default function SettingsWindow({
  showSettings,
  setShowSettings,
  darkMode,
  setDarkMode,
  minimizeSideBar,
  setMinimizeSideBar,
}) {
  const closeWindow = () => {
    setShowSettings(false);
  };

  const [resetState, setResetState] = useState(false);

  useEffect(() => {
    if (resetState) {
      sessionStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, [resetState]);

  const toggleResetState = () => {
    setResetState(true);
  };

  return (
    <>
      <div onClick={closeWindow} className="settings-window-background"></div>
      <div className="settings-window">
        <div className="dialogue-heading">
          <span>Settings</span>
          <button className="btn-close-dialogue" onClick={closeWindow}>
            <img src={iconClose} />
          </button>
        </div>
        <div className="settings-section">
          <h3>Appearance</h3>
          <hr style={{ opacity: "0.3", margin: "5px 0 10px 0" }} />
          <ToggleSwitch
            state={darkMode}
            setState={setDarkMode}
            label="Dark mode:"
            style={{ fontSize: "12px" }}
          />
          <ToggleSwitch
            state={minimizeSideBar}
            setState={setMinimizeSideBar}
            label="Collapse side bar:"
            style={{ fontSize: "12px" }}
          />
        </div>
        <div style={{ marginTop: "30px" }} className="settings-section">
          <h3>Other</h3>
          <hr style={{ opacity: "0.3", margin: "5px 0 10px 0" }} />
          <TigerButton
            style={resetState ? { opacity: "0.6", cursor: "default" } : {}}
            onClick={toggleResetState}
            label={resetState ? "Session cleared" : "Reset session"}
          />
        </div>
      </div>
    </>
  );
}
