import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { Scatter } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function MultiGraph({ type, graph, dataArray }) {
  const labels = Array.from({ length: graph.x.max + 1 }, (_, index) =>
    index.toString()
  );

  const fillType = type === "single" ? 1 : true;

  const datasets = dataArray.map((dataset, index) => ({
    type: "line",
    label: dataset.label,
    data: dataset.data,
    borderColor: dataset.borderColor,
    borderDash: dataset.borderDash,
    borderWidth: dataset.borderWidth,
    // fill: `${index > 4 ? false : "-1"}`,
    // fill: `${index > 3 ? false : "-1"}`,
    fill: dataset.fill != null ? dataset.fill : fillType,
    // fill: `${dataArray.length ==  ? true : 1}`,
    backgroundColor: dataset.backgroundColor,
    pointRadius:
      dataset.pointRadius == null
        ? 0
        : function (context) {
            const value = context.dataset.data[context.dataIndex].count;
            // Adjust the base size and scaling factor according to your needs
            return value * 0.03; // Example: scale size by count value
          },
  }));

  const options = {
    scales: {
      x: {
        title: {
          display: graph.x.title !== "none",
          text: graph.x.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.x.min,
        max: graph.x.max,
        ticks: {
          autoSkip: true,
          maxTicksLimit: graph.x.step,
        },
      },
      y: {
        title: {
          display: graph.y.title !== "none",
          text: graph.y.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.y.min,
        max: graph.y.max,
        ticks: {
          stepSize: graph.y.step,
        },
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {},
      },
      title: {
        display: graph.label !== "none",
        text: graph.label,
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex < 6;
        },
        mode: "index",
        intersect: false,
        yAlign: "bottom",
        titleAlign: "center",
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
    },
  };

  return <Chart type="line" options={options} data={{ labels, datasets }} />;
}
