import "./tiger-button.styles.scss";

/**
 * Button component to run an onClick function
 *
 * @component
 * @example
 * <TigerButton label="Click me" onClick={handleClick} style={{color: "red"}}/>
 */
export default function TigerButton({ label, onClick, style }) {
  return (
    <button style={style} onClick={onClick} className="btn-tiger">
      {label}
    </button>
  );
}
