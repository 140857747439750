import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StatisticsChart({
  data,
  label,
  labels,
  backgroundColor,
  borderColor,
  animation = true,
  row,
  colStart,
  colEnd,
  rowStart,
  rowEnd,
}) {
  const style = {
    doughnutContainer: {
      justifyContent: "center",
      display: "flex",
      border: "1px solid gainsboro",
      padding: "10px",
      borderRadius: "10px",
      background: "#f7f7f7",
      alignItems: "center",
      flexDirection: "column",
      flexShrink: "1",
      gridColumnStart: colStart,
      gridColumnEnd: colEnd,
      gridRowStart: rowStart,
      gridRowEnd: rowEnd,
    },

    h1: {
      fontWeight: " 700",
      opacity: "0.7",
    },
  };

  const dataData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: false,
  };
  return (
    <div
      className={"statistics-grid-item row-" + row}
      style={style.doughnutContainer}
    >
      <h1 style={style.h1}>{label}</h1>
      <Doughnut options={!animation && options} data={dataData} />
    </div>
  );
}
