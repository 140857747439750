import "./slurf-graph.style.scss";
import { arrayGen } from "../../utils/micro-helpers";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function SLURFGraph({ graph, data }) {
  // const labels = Array.from({ length: graph.x.max + 1 }, (_, index) =>
  //   index.toString()
  // );

  const options = {
    scales: {
      x: {
        title: {
          display: graph.x.title !== "none",
          text: graph.x.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
      },
      y: {
        title: {
          display: graph.y.title !== "none",
          text: graph.y.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: graph.label,
      },
    },
  };

  const optionsReal = {
    scales: {
      x: {
        type: "linear",
        title: {
          display: graph.x.title !== "none",
          text: graph.x.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.x.min,
        max: graph.x.max,
        ticks: {
          maxTicksLimit: graph.x.step,
        },
      },
      y: {
        type: "linear",
        title: {
          display: graph.y.title !== "none",
          text: graph.y.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.y.min,
        max: graph.y.max,
        ticks: {
          stepSize: graph.y.step,
        },
      },
    },
    pointRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {},
      },
      title: {
        display: graph.label !== "none",
        text: graph.label,
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex < 6;
        },
        mode: "index",
        intersect: false,
        yAlign: "bottom",
        titleAlign: "center",
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
    },
  };

  // Example usage:
  const exampleData = {
    0.12: { x_upp: [0, 1, 3, 5, 2, 2], x_low: [6, 2, 3, 2, 1, 4] },
    0.33: { x_upp: [7, 2, 2, 3, 2, 2], x_low: [2, 2, 3, 2, 4, 4] },
  };

  function getDatasets(data) {
    let datasets = [];

    try {
      if (typeof data === "object") {
        Object.entries(data).map((item, key) => {
          let darker = item[0] > 0.065;
          let dataset_x_upp = item[1]["x_upp"];
          let dataset_x_low = item[1]["x_low"];
          datasets.push({
            label: item[0] + " up",
            data: dataset_x_upp,
            borderColor: "#black",
            borderWidth: "1px",
            fill: 1,
            backgroundColor: darker ? "#0000ff22" : "#00003d5c",
          });
          datasets.push({
            label: item[0] + " low",
            data: dataset_x_low,
            borderColor: "black",
            borderWidth: "1px",
            fill: 1,
            backgroundColor: darker ? "#0000ff22" : "#00003d5c",
          });
        });
        console.log("Data ok");
        return datasets;
      } else {
        throw new Error("Error: Data not ok. Type is " + typeof data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const labels = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const exampleDataTwo = {
    labels,
    datasets: [
      {
        label: "0.022 up",
        data: [
          0.48474578753827835, 1.523362768088683, 2.012643295888329,
          2.5262699257489927, 3.0401293390406754, 3.6032164311807846,
          4.053606957467993, 4.514002645028661, 5.081053852586256,
          5.081053852586256,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.022 low",
        data: [
          0.4847457875062839, 0.4847457875062839, 1.03771251728878,
          1.5233627680433601, 2.0126432958486413, 2.526269925700806,
          3.040129339006337, 3.6032164311418864, 4.053606957429257,
          4.514002644984146,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.049 up",
        data: [
          0.8144148756298271, 1.9637443024162489, 2.5421554406229356,
          3.1022683975409753, 3.678772198990646, 4.175857939816641,
          4.7628692745909555, 5.386950251721109, 5.8109286582095026,
          5.8109286582095026,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.049 low",
        data: [
          0.15390579541645588, 0.15390579541645588, 0.687992957560898,
          1.1014937794118294, 1.5660932410694404, 1.8815591158006229,
          2.3118128693887203, 2.9328421582645054, 3.3891706814268665,
          3.933224849253914,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.065 up",
        data: [
          0.8469116696813369, 2.0466205777392346, 2.6486140335293964,
          3.2639078799695773, 3.846738709900849, 4.368689656796274,
          4.9107688036386, 5.456552636858856, 6.017900158682296,
          6.017900158682296,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.065 low",
        data: [
          0.104879969022993, 0.104879969022993, 0.5239437928412362,
          1.0573649333563333, 1.2931396301444187, 1.7277074673805268,
          2.2014089872266864, 2.7691931515189996, 3.2786797958248886,
          3.8314493395724574,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.095 up",
        data: [
          0.9097259562300658, 2.1356153327738054, 2.7580376766502135,
          3.4256790107134725, 3.9043644438025282, 4.586481094598149,
          5.216222407965278, 5.54171938480655, 6.121870350606349,
          6.121870350606349,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.095 low",
        data: [
          0.05331818301869671, 0.05331818301869671, 0.3701584361790671,
          0.7545454980374592, 1.2169404277095992, 1.548920084935069,
          1.9275482701167135, 2.510540876555131, 3.081504090986455,
          3.757821461103736,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.118 up",
        data: [
          0.9230921139755679, 2.263001676728099, 2.851528514367517,
          3.4504106261855134, 4.0558152830110705, 4.782844065468281,
          5.249398104557857, 5.7839763256820245, 6.207186316330139,
          6.207186316330139,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.118 low",
        data: [
          0.036424071199446455, 0.036424071199446455, 0.34866499404668677,
          0.7290290133423081, 1.1848722808171277, 1.4984993917929668,
          1.8929378412902755, 2.4838764795934574, 3.009445440980125,
          3.589085916120448,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.154 up",
        data: [
          0.9423776509132636, 2.329643098248621, 2.8900681229596206,
          3.5922520180453894, 4.106110912895991, 4.869882166525489,
          5.286916205128883, 6.091683747963295, 6.458797847320945,
          6.458797847320945,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.154 low",
        data: [
          0.021209392064733885, 0.021209392064733885, 0.3434737808648517,
          0.65103950623547, 0.928592807983988, 1.3430477705574924,
          1.8677493235010818, 2.3582285820528117, 2.9531573734778047,
          3.030175162939233,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.222 up",
        data: [
          0.9542767216642549, 2.4003124152832362, 2.98928288621846,
          3.739967746091143, 4.164185618410716, 5.026777633298581,
          5.406444090420625, 6.176543729292272, 6.80049644175479,
          6.80049644175479,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.222 low",
        data: [
          0.01503227134599182, 0.01503227134599182, 0.2990694200059225,
          0.6221885517233902, 0.8727444287415618, 1.2987257753673445,
          1.68694831001373, 2.2003105366549693, 2.6727936807736685,
          2.9952006283475363,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.4 up",
        data: [
          0.9676282945539179, 2.4719037120259015, 3.225754475942986,
          3.8052630975655495, 4.406669132382103, 5.277206768006329,
          5.678490225440186, 6.443845040894562, 7.115277372566307,
          7.115277372566307,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.4 low",
        data: [
          0.013876358186484009, 0.013876358186484009, 0.24368888570997185,
          0.5520798592941875, 0.8514742790875705, 1.0746232423314273,
          1.6221425492402173, 2.0105808487446395, 2.614546793783526,
          2.8595094665577996,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.667 up",
        data: [
          0.9833096010639429, 2.55192182560537, 3.391549447733315,
          3.911968575219009, 4.651265472837379, 5.328249137539525,
          5.901607862358151, 6.489671000502627, 7.191943899577785,
          7.191943899577785,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "0.667 low",
        data: [
          0.010439012672647829, 0.010439012672647829, 0.1976925397930575,
          0.3732354181973073, 0.8008107078622166, 1.013122576471338,
          1.509772849849418, 1.9942611456453132, 2.59322425734089,
          2.829170067027047,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "2.0 up",
        data: [
          0.9968509595234567, 2.7129482555671207, 3.452037082155596,
          4.0536140819055655, 4.669240228811873, 5.338506494391372,
          6.0467351558013025, 6.730311500144633, 7.492480714045011,
          7.492480714045011,
        ],
        borderColor: "#black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
      {
        label: "2.0 low",
        data: [
          0.002943471668599846, 0.002943471668599846, 0.18037803515498932,
          0.24701761037664305, 0.7932630863223811, 0.8822441013424087,
          1.4181154041221242, 1.7260319352866038, 2.5907335007327044,
          2.7602215846211817,
        ],
        borderColor: "black",
        borderWidth: "1px",
        fill: 1,
        backgroundColor: "#0000ff22",
      },
    ],
  };

  const datasets = getDatasets(data);
  console.log(datasets);

  return <Line options={options} data={{ labels, datasets }} />;
}
