import { useEffect, useState } from "react";
import "./statistics.style.scss";
import StatisticsSingle from "../../components/StatisticsSingle/statistics-single.component";
import iconInspections from "../../assets/icons8-inspection-80.png";
import iconPipeCount from "../../assets/icons8-bar-chart-80.png";
import iconNetworkLength from "../../assets/icons8-mind-map-80.png";
import iconYear from "../../assets/icons8-planner-80.png";
import StatisticsChart from "../../components/StatisticsChart/statistics-chart.component";
import { Link } from "react-router-dom";
import StatisticsHistogram from "../../components/StatisticsHistogram/statistics-single.component";
import PageTitleCard from "../../components/PageTitleCard/page-title-card.component";

export default function Statistics({specialState}) {
  // Set to use a local server instance (change fetch URL)
  const localServer = false;
  // Set server URL and local instance location
  const serverUrl = localServer
    ? "http://127.0.0.1:5000"
    : "https://primavera.codesystems.org";

  const [statisticsSingles, setStatisticsSingles] = useState({
    totalInspections: 0,
    pipesCount: 0,
    combinedPipesLength: 0,
    constructionYear: 0,
    constructionYearCount: 0,
    pipeAgeHistogram: [],
    pipeLengthHistogram: [],
    pipeDiameterHistogram: [],
  });

  const [statisticsGraphs, setStatisticsGraphs] = useState({
    materials: [],
    diameters: [],
  });

  const [isLoading, setIsLoading] = useState(false);


  function getDefaultTableExists(){
    return sessionStorage.getItem("gis--table-data") !== null;
  }

  const [tableExists, setTableExists] = useState(getDefaultTableExists());

  useEffect(()=>{
    setTableExists(getDefaultTableExists() || specialState);
  },[sessionStorage.getItem("gis--table-data"), specialState])


  const getStatisticsSingles = () => {
    setIsLoading(true);
    fetch("https://primavera.codesystems.org" + "/s-all-data/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response not ok");
        }

        return response.json();
      })
      .then((data) => {
        setStatisticsSingles({
          totalInspections: data["s--total-inspections"],
          pipesCount: data["s--pipes-count"],
          combinedPipesLength: data["s--pipe-length"],
          constructionYear: data["s--construction-year"],
          constructionYearCount: data["s--construction-year-count"],
          pipeAgeHistogram: data["s--pipe-age-hist"],
          pipeLengthHistogram: data["s--pipe-length-hist"],
          pipeDiameterHistogram: data["s--pipe-dia-hist"],
        });
        setStatisticsGraphs({
          materials: [data["s--m-concrete"], data["s--m-pvc"]],
          diameters: [data["s--d-one"], data["s--d-two"], data["s--d-three"]],
        });
        console.log(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  useEffect(() => {
    if (tableExists) {
      getStatisticsSingles();
    }
  }, [tableExists]);

  function getHistogramPercentages(array) {
    let returnArray = [];
    array.map((number, key) => {
      returnArray.push(number / statisticsSingles.pipesCount);
    });
    return returnArray;
  }

  return (
    <>
      <div className="content statistics-content">
        <PageTitleCard showInfoButton={true} title="GIS Statistics">
          This module presents key statistics related to the{" "}
          <strong>sewer network dataset</strong>, covering aspects such as{" "}
          <strong>material</strong>, <strong>pipe length</strong>,{" "}
          <strong>diameter</strong>, and <strong>age of the pipes</strong>. It
          provides a concise overview, making it easier to understand the
          dataset's characteristics and their implications for the{" "}
          <strong>network's performance</strong> and{" "}
          <strong>maintenance needs</strong>.
        </PageTitleCard>
        {!tableExists && (
          <div className="statistics-notice">
            <span>
              Please generate a network
              <Link to="/gis-map">Go to GIS Map module</Link>
            </span>
          </div>
        )}
        <div className={!tableExists && "statistics-filter"}>
          <div className="statistics-grid-container">
            <StatisticsSingle
              colStart={1}
              colEnd={3}
              title="Inspections per pipe"
              value={(
                statisticsSingles.pipesCount /
                statisticsSingles.totalInspections
              ).toFixed(2)}
              image={iconInspections}
            />
            <StatisticsSingle
              colStart={3}
              colEnd={5}
              title="Number of pipes"
              value={statisticsSingles.pipesCount}
              image={iconPipeCount}
            />
            <StatisticsSingle
              colStart={5}
              colEnd={7}
              title="Total network length"
              value={statisticsSingles.combinedPipesLength + "km"}
              image={iconNetworkLength}
            />

            <StatisticsChart
              colStart={1}
              colEnd={3}
              rowStart={2}
              rowEnd={5}
              animation={tableExists}
              backgroundColor={[
                "rgba(20, 85, 255, 0.2)",
                "rgba(0, 191, 89, 0.2)",
              ]}
              borderColor={["rgba(20, 85, 255, 1)", "rgba(0, 191, 89, 1)"]}
              labels={["Concrete", "PVC"]}
              data={tableExists ? statisticsGraphs.materials : [1, 1]}
              label="Materials"
            />
            <StatisticsHistogram
              graph={{
                x: {
                  title: "Age",
                },
                y: {
                  title: "Counts",
                },
              }}
              data={statisticsSingles.pipeAgeHistogram}
              title="Pipe age"
              value={null}
              rowStart={2}
              rowEnd={3}
              colStart={3}
              colEnd={5}
            />
            <StatisticsHistogram
              graph={{
                x: {
                  title: "Centimeter",
                },
                y: {
                  title: "Counts",
                },
              }}
              data={statisticsSingles.pipeLengthHistogram}
              title="Pipe diameter"
              value={null}
              rowStart={3}
              rowEnd={4}
              colStart={3}
              colEnd={5}
              labels={[0, 40, 80, 120, 160, 200, 240, 280, 320, 360, 400]}
            />
            <StatisticsHistogram
              data={statisticsSingles.pipeDiameterHistogram}
              graph={{
                x: {
                  title: "Meter",
                },
                y: {
                  title: "Counts",
                },
              }}
              title="Pipe lengths"
              value={null}
              rowStart={4}
              rowEnd={5}
              colStart={3}
              colEnd={5}
              labels={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            />
            <StatisticsChart
              colStart={5}
              colEnd={7}
              rowStart={2}
              rowEnd={5}
              animation={tableExists}
              backgroundColor={[
                "rgba(20, 85, 255, 0.1)",
                "rgba(20, 85, 255, 0.4)",
                "rgba(20, 85, 255, 0.7)",
              ]}
              borderColor={["rgba(20, 85, 255, 1)"]}
              labels={["<200cm", "200cm-400cm", ">400cm"]}
              data={tableExists ? statisticsGraphs.diameters : [1, 1, 1]}
              label="Pipe diameters"
            />
          </div>
        </div>
      </div>
    </>
  );
}
