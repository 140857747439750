import { useState, useEffect } from "react";
import GISMap from "../../components/GISMap/gis-map.component";
import GISTable from "../../components/GISTable/gis-table.component";
import OptionsHub from "../../components/OptionsHub/options-hub.component";
import GISPipesTab from "../../components/Tabs/gis-pipes-tab.component";
import useSession from "../../hooks/useSession";
import iconPlot from "../../assets/icons8-play-40.png";
import PageTitleCard from "../../components/PageTitleCard/page-title-card.component";

export default function GIS({gisNumberInputs, setGisNumberInputs, handleGenerateNetwork, tableData, setTableData, dataRetrievalState, setDataRetrievalState, gisParameters, setGisParameters}) {



  // n_levels: gisParameters["gis-p--n"],
  // noise: gisParameters["gis-p--noise"],
  // m_pvc: gisParameters["gis-m--pvc"],
  // m_concrete: gisParameters["gis-m--concrete"],
  // d_surface: gisParameters["gis-d--surface"],
  // d_root: gisParameters["gis-d--root"],
  // d_crack: gisParameters["gis-d--crack"],





  useEffect(() => {
    sessionStorage.setItem("gis-md", JSON.stringify(gisParameters));
  }, [gisParameters]);

  useEffect(() => {
    setGisParameters((prev) => ({
      ...prev,
      "gis-p--n": gisNumberInputs["gis-p--n"] || 4,
      "gis-p--noise": gisNumberInputs["gis-p--noise"] || 0.5,
    }));
  }, [gisNumberInputs]);

  const [isGenerationModePreset, setIsGenerationModePreset] = useState(true);

  const handleGenerationModeChange = (event) => {
    setIsGenerationModePreset(event.target.value === "preset" ? true : false);
  };

 

  useEffect(() => {
    console.log("REE", dataRetrievalState);
  }, [dataRetrievalState]);

  return (
    <>
      <div className="content gis-content">
        <PageTitleCard showInfoButton={false} title="GIS Map">
          In this module, you'll get a glimpse of our{" "}
          <strong>inspection dataset</strong>. For{" "}
          <strong>detailed statistics</strong>, check out the{" "}
          <strong>GIS Statistics module</strong>. Additionally, the{" "}
          <strong>Prognostics module</strong> uses this dataset to build{" "}
          <strong>predictive models</strong>, offering insights into{" "}
          <strong>future trends</strong>. This{" "}
          <strong>practical approach</strong> showcases how data can inform and
          enhance our understanding of infrastructure systems. Go ahead and play
          with the <strong>different options</strong> and generate your own{" "}
          <strong>toy-dataset</strong>!
        </PageTitleCard>
        <div style={{ display: "flex", flexDirection: "row", flexGrow: "1" }}>
          <OptionsHub>
            <GISPipesTab
              gisNumberInputs={gisNumberInputs}
              setGisNumberInputs={setGisNumberInputs}
              setGisParameters={setGisParameters}
              setDataRetrievalState={setDataRetrievalState}
              gisParameters={gisParameters}
            />
            <div className="options-footer">
              <div className="generate-btn">
                <button className="btn-fit" onClick={handleGenerateNetwork}>
                  <img src={iconPlot} />
                  Generate Network
                </button>
                {/* <select
                  value={false ? "preset" : "new"}
                  // value={isGenerationModePreset ? "preset" : "new"}
                  onChange={handleGenerationModeChange}
                  id="generate-mode"
                  style={{ pointerEvents: "none" }}>
                  <option value="preset">Preset</option>
                  <option value="new">New</option>
                </select> */}
              </div>
            </div>
          </OptionsHub>
          <div className="content-container">
            <div className="example-map">
              <h1>Example map</h1>
              <div className="map-image"></div>
            </div>
            <GISTable
              dataRetrievalState={dataRetrievalState}
              tableData={tableData}
            />
          </div>
          {/* <GISTable /> */}
        </div>
      </div>
    </>
  );
}
