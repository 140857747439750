import GISMap from "../../components/GISMap/gis-map.component";
import HomeCard from "../../components/HomeCard/home-card.component";
import useSession from "../../hooks/useSession";
import iconOpen from "../../assets/icons8-open-48.png";

export default function Home() {
  const [showMap, setShowMap] = useSession("gis--show-map", false);

  return (
    <>
      <div className="content home-content">
        <div className="title-card">
          <div className="title-heading">
            Welcome to the
            <b style={{ padding: "0 5px" }}>
              {" "}
              PrimaVera Infrastructure Demonstrator{" "}
            </b>
            application
          </div>
          <a
            href="https://primavera-project.com/"
            target="_blank"
            className="title-link"
          >
            Visit project website
          </a>
        </div>
        <p className="home-paragraph">
          <p>
            {" "}
            Welcome to the Primavera Infrastructure Demonstrator! Here, we
            showcase the research outcomes achieved throughout our project's
            lifespan. Dive into our case study on sewer networks, brought to
            life through three interactive modules: GIS Map, GIS Statistics, and
            Prognostics. Each module is designed to provide you with a deep dive
            into relevant information and significant results surrounding our
            case study.
          </p>
          <p>
            Curious about the PrimaVera project? Discover more at our
            <a target="_blank" href="https://primavera-project.com">
              website <img src={iconOpen} />
            </a>
          </p>
        </p>
        <div className="card-container">
          <HomeCard
            title="GIS Map"
            paragraph="Presents inspection data."
            link="/gis-map"
            image="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/map-cover-color.png"
            video="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/PRIMAVERA-export-map.mp4"
          />
          <HomeCard
            title="GIS Statistics"
            paragraph="Summarize revelant statistics."
            link="/gis-statistics"
            image="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/stats-cover-color.png"
            video="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/PRIMAVERA-export-stat.mp4"
          />
          <HomeCard
            title="Prognostics"
            paragraph="Display algorithms used for prognostics."
            link="/prognostics"
            image="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/prog-cover-color.png"
            video="https://media-storage-avocado.s3.eu-central-1.amazonaws.com/PRIMAVERA-export-prog.mp4"
          />
        </div>
      </div>

      {/* <GISMap setShowMap={setShowMap} showMap={showMap} /> */}
    </>
  );
}
