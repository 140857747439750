import iconExport from "../../assets/icon-export-download.png";
import "./content-menu.style.scss";

export default function ContentMenu({
  focusGraph,
  foldStateDTMC,
  foldStateUKF,
  graphView,
  handleFocusChange,
  handleViewChange,
}) {
  return (
    <>
      <div className="content-menu">
        <div className="menu-view-options">
          <span>View: </span>
          <div className="btn-view-container">
            <button
              className={
                graphView != "all" ? "btn-view-all" : "btn-view-all-active"
              }
              onClick={() => handleViewChange("all")}></button>
            <button
              className={
                graphView != "focused"
                  ? "btn-view-focused"
                  : "btn-view-focused-active"
              }
              onClick={() => handleViewChange("focused")}></button>
            <button
              className={
                graphView != "single"
                  ? "btn-view-single"
                  : "btn-view-single-active"
              }
              onClick={() => handleViewChange("single")}></button>
          </div>
          {graphView === "focused" && foldStateDTMC && (
            <>
              <span>Left: </span>
              <select
                value={focusGraph}
                onChange={handleFocusChange}
                id="options-view-focused">
                <option value="graph-one">k = 1</option>
                <option value="graph-two">k = 2</option>
                <option value="graph-three">k = 3</option>
                <option value="graph-four">k = 4</option>
                <option value="graph-five">k = 5</option>
              </select>
            </>
          )}
        </div>
        <div style={{ display: "none" }} className="menu-export-container">
          <button className="btn-export-data">
            <img src={iconExport} />
            Export
          </button>
        </div>
      </div>
    </>
  );
}
