export function arrayToColumn(array) {
  let output = "";

  for (let i = 0; i < array.length; i++) {
    output = output + array[i] + "\n";
  }

  return output;
}

export function mergeTwoArraysToColumns(arrayOne, arrayTwo) {
  let output = "";

  for (let i = 0; i < arrayOne.length; i++) {
    output = output + arrayOne[i] + ", " + arrayTwo[i] + "\n";
  }

  return output;
}

export function mergeArraysToColumns(arrays) {
  let output = "";
  let line = "";
  let numberOfArrays = arrays.length;

  // Find the maximum length among the arrays
  let maxLength = 0;
  for (let i = 0; i < numberOfArrays; i++) {
    if (arrays[i] && arrays[i].length > maxLength) {
      maxLength = arrays[i].length;
    }
  }

  for (let i = 0; i < maxLength; i++) {
    line = "";

    for (let j = 0; j < numberOfArrays; j++) {
      if (arrays[j] && arrays[j][i] !== undefined) {
        if (line !== "") {
          line += ", "; // Add a comma if something follows
        }
        line += arrays[j][i];
      }
    }

    output += line + "\n"; // Add all lines
  }

  return output;
}

// export function exportCSV(headers, columns, fileName) {}
export function exportCSV() {
  console.log(arrayToColumn(["hello", "hi", "one", "two", "three", "four"]));
}
