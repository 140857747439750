import React, { useState, useEffect, useRef } from 'react';
import './context-menu.style.scss'

const ContextMenu = ({ menuItems, targetRef }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleContextMenu = (event) => {
    if (targetRef.current && targetRef.current.contains(event.target)) {
      event.preventDefault();
      setPosition({ x: event.clientX, y: event.clientY });
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const handleClick = () => {
    setVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  return (
    visible && (
      <ul
        className="context-menu"
        style={{ top: position.y, left: position.x, position: 'absolute', zIndex: 1000 }}
      >
        {menuItems.map((item, index) => (
          <li key={index} onClick={item.onClick}>
            {item.label}
          </li>
        ))}
      </ul>
    )
  );
};

export default ContextMenu;
