import "./statistics-single.style.scss";

export default function StatisticsSingle({
  colStart,
  colEnd,
  rowStart,
  rowEnd,
  title = "Title",
  value,
  image,
  row,
}) {
  return (
    <div
      style={{
        gridColumnStart: colStart,
        gridColumnEnd: colEnd,
        gridRowStart: rowStart,
        gridRowEnd: rowEnd,
      }}
      className={"single-item statistics-grid-item row-" + row}
    >
      <img src={image} />
      <h2>{title}</h2>
      <h1>{value}</h1>
    </div>
  );
}
