import "./ukf-graph.styles.scss";
import { arrayGen } from "../../utils/micro-helpers";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function UKFGraph({ graph, data }) {
  // Define the values on the x-axis; Here: inspection values + predicted upper bound
  const labels = data.inspectionsX.concat(data.predictedLowX);

  // Compute the y-threshold line as the y value over the entire x-axis
  const yThresholdData = arrayGen(data.yThreshold, labels.length);

  const predictedMean = {
    label: "Predicted mean",
    // Since this does not start at x = 0, draw existing x values (with nulls)...
    data: arrayGen(
      null,
      data.filteringStageY[data.filteringStageY.length - 1]
    ).concat(data.predictedMeanY),
    borderColor: "rgba(50, 170, 50, 1)",
    backgroundColor: "rgba(255, 255, 255, 0)",
  };

  const predictedUp = {
    label: "Predicted upper",
    // ...same behavior here...
    data: arrayGen(
      null,
      data.filteringStageY[data.filteringStageY.length - 1]
    ).concat(data.predictedUpY),
    borderColor: "rgba(255, 50, 50, 1)",
    backgroundColor: "rgba(0, 0, 255, 0.2)",
  };

  const predictedLow = {
    label: "Predicted low",
    // ...and here!
    data: arrayGen(
      null,
      data.filteringStageY[data.filteringStageY.length - 1]
    ).concat(data.predictedLowY),
    borderColor: "rgba(255, 50, 50, 1)",
    backgroundColor: "rgba(0, 0, 255, 0.2)",
  };

  const yThreshold = {
    label: "Failure threshold",
    data: yThresholdData,
    borderColor: "rgba(112, 0, 55, 1)",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderDash: [6, 2],
    borderWidth: 3.5,
  };

  const filteringStage = {
    label: "Filtering stage",
    data: data.filteringStageY,
    borderColor: "rgba(50, 50, 255, 1)",
  };

  const inspections = {
    label: "Inspections",
    data: data.inspectionsY,
    pointBackgroundColor: "orange",
    fill: false,
    pointRadius: 5,
    showLine: false,
  };

  const optional = {
    label: "Placeholder",
    data: data.filteringStageY.concat(data.predictedMeanY),
    borderColor: "rgba(0,100,0,1)",
    borderDash: [5, 7, 15],
    borderWidth: 6.5,
  };

  let datasets = [
    inspections,
    filteringStage,
    yThreshold,
    predictedMean,
    predictedUp,
    predictedLow,
  ];

  // If switch is toggled, render additional graph
  datasets = data.toggleOptional ? [optional, ...datasets] : datasets;

  const options = {
    scales: {
      x: {
        type: "linear",
        title: {
          display: graph.x.title !== "none",
          text: graph.x.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.x.min,
        max: graph.x.max,
        ticks: {
          step: graph.x.step,
        },
      },
      y: {
        title: {
          display: graph.y.title !== "none",
          text: graph.y.title,
          font: {
            family: "Inter",
            weight: 500,
          },
        },
        min: graph.y.min,
        max: graph.y.max,
        ticks: {
          stepSize: graph.y.step,
        },
      },
    },
    pointRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {},
      },
      title: {
        display: graph.label !== "none",
        text: graph.label,
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex < 6;
        },
        mode: "index",
        intersect: false,
        yAlign: "bottom",
        titleAlign: "center",
        font: {
          family: "Inter",
          weight: 600,
        },
      },
      hover: {
        mode: "index",
        intersect: false,
      },
    },
  };

  console.log(datasets);

  return <Line options={options} data={{ labels, datasets }} />;
}
