import { Link } from "react-router-dom";
import iconPlot from "../../assets/icons8-play-40.png";
import iconClose from "../../assets/icons-close.png";

import "./home-card.style.scss";
import InfoButton from "../InfoButton/info-button.component";
import { useState } from "react";

export default function HomeCard({ title, image, paragraph, link, video }) {
  const [showInfo, setShowInfo] = useState(false);

  const closeWindow = () => {
    setShowInfo(false);
  };

  const openWindow = () => {
    setShowInfo(true);
  };
  return (
    <>
      {showInfo && (
        <>
          <div onClick={closeWindow} className="info-window-background"></div>
          <div
            style={{ width: "780px", height: "495px" }}
            className="info-window"
          >
            <div className="dialogue-heading">
              <span>{title}</span>
              <button className="btn-close-dialogue" onClick={closeWindow}>
                <img src={iconClose} />
              </button>
            </div>
            <div className="dialogue-content">
              <video autoplay="true" loop src={video} />
            </div>
          </div>
        </>
      )}
      <div className="home-card">
        <h6>{title}</h6>
        <div
          style={{ backgroundImage: "url(" + image + ")" }}
          className="card-image"
        >
          <Link to={link} className="card-btn">
            {"Open ->"}
          </Link>
          <div onClick={openWindow} className="card-btn-play">
            <img src={iconPlot} />
            Play video
          </div>
        </div>
        <p>{paragraph}</p>
      </div>
    </>
  );
}
