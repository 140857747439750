import "./gis-table.style.scss";
import { useEffect, useState } from "react";
import iconDownload from "../../assets/icon-download.png";
import iconLoading from "../../assets/icons8-loading-circle.gif";
import iconReady from "../../assets/icons8-check-50.png";

const tableNetwork = {
  headers: [
    "ID",
    "Material",
    "Pipeline length",
    "Diameter",
    "Construction year",
    "Level",
  ],
};

const tableInspections = {
  headers: ["ID", "Pipe id", "Inspection year"],
};

function TableOptions({ setSelectedTable, selectedTable, loadState }) {
  // Set to use a local server instance (change fetch URL)
  const localServer = false;
  // Set server URL and local instance location
  const serverUrl = localServer
    ? "http://127.0.0.1:5000"
    : "https://primavera.codesystems.org";

  const [pipeTableCSV, setPipeTableCSV] = useState();

  const [pipeTableCSVLoad, setPipeTableCSVLoad] = useState("idle");

  const handlePipeTableDownload = () => {
    if (pipeTableCSVLoad != "complete") {
      setPipeTableCSVLoad("loading");
    }
    fetch("https://primavera.codesystems.org" + "/gis-pipes-download/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPipeTableCSV(data);
      })
      .finally(() => {
        setPipeTableCSVLoad("complete");
        createPipeCSV();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const [inspectionsTableCSV, setInspectionsTableCSV] = useState();

  const [inspectionsTableCSVLoad, setInspectionsTableCSVLoad] =
    useState("idle");

  const handleInspectionsTableDownload = () => {
    if (inspectionsTableCSVLoad != "complete") {
      setInspectionsTableCSVLoad("loading");
    }
    fetch("https://primavera.codesystems.org" + "/gis-inspections-download/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response not ok");
        }
        return response.json();
      })
      .then((data) => {
        setInspectionsTableCSV(data);
      })
      .finally(() => {
        setInspectionsTableCSVLoad("complete");
        createInspectionsCSV();
      })

      .catch((error) => {
        console.log("Error", error);
      });
  };

  const createPipeCSV = () => {
    let hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURI(pipeTableCSV.csv_data);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = selectedTable + "-table-export.csv";
    hiddenElement.click();
  };

  const createInspectionsCSV = () => {
    let hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," + encodeURI(inspectionsTableCSV.csv_data);
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = selectedTable + "-table-export.csv";
    hiddenElement.click();
  };

  useEffect(() => {
    if (loadState != "complete") {
      setPipeTableCSVLoad("idle");
      setInspectionsTableCSVLoad("idle");
    }
  }, [loadState]);

  return (
    <div className="table-options">
      <div className="heading">
        <div className="heading-left">
          <h1>Pipelines</h1>
          <h6>Pipeline data management</h6>
        </div>
        <div className="heading-right">
          <button
            className={
              (selectedTable === "inspections" &&
                inspectionsTableCSVLoad === "complete" &&
                "btn-download-table btn-download-table-ready") ||
              (selectedTable === "pipes" &&
                pipeTableCSVLoad === "complete" &&
                "btn-download-table btn-download-table-ready") ||
              "btn-download-table"
            }
            onClick={
              selectedTable === "pipes"
                ? handlePipeTableDownload
                : handleInspectionsTableDownload
            }
          >
            {selectedTable === "pipes" && (
              <img
                style={{ width: "20px", height: "20px", opacity: "0.6" }}
                src={
                  (pipeTableCSVLoad === "complete" && iconReady) ||
                  (pipeTableCSVLoad === "loading" && iconLoading) ||
                  iconDownload
                }
              />
            )}
            {selectedTable === "inspections" && (
              <img
                style={{ width: "20px", height: "20px", opacity: "0.6" }}
                src={
                  inspectionsTableCSVLoad != "loading"
                    ? inspectionsTableCSVLoad === "complete"
                      ? iconReady
                      : iconDownload
                    : iconLoading
                }
              />
            )}
            Download table
          </button>
        </div>
      </div>
      <div className="table-selector">
        <span
          className={selectedTable === "pipes" && "selected-table"}
          onClick={() => {
            setSelectedTable("pipes");
          }}
        >
          Pipes
        </span>
        <span
          className={selectedTable === "inspections" && "selected-table"}
          onClick={() => {
            setSelectedTable("inspections");
          }}
        >
          Inspections
        </span>
      </div>
    </div>
  );
}

function PipesTable({ loadState, tableData }) {
  // id, material, length, diameter, construction_year, level
  // [1, 'Concrete', 50, 600, 1930, '0,3']
  return (
    <>
      <div className="table-header-container">
        <table className="table-header">
          <tr>
            {tableNetwork.headers.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
          <tr style={{ visibility: "hidden" }}>
            <td>
              <b>10000</b>
            </td>
            <td>Concrete</td>
            <td>600m</td>
            <td>50m</td>
            <td>1930</td>
            <td>0,2,6,2,3</td>
          </tr>
        </table>
      </div>
      {loadState != "complete" && (
        <div
          className={loadState === "loading" ? "table-loading" : "table-empty"}
        >
          {loadState === "idle" ? (
            "Please generate a network"
          ) : (
            <>
              Generating network<span className="loading-dot-one">.</span>
              <span className="loading-dot-two">.</span>
              <span className="loading-dot-three">.</span>
            </>
          )}
        </div>
      )}
      <div className="table-content-container">
        <table className="table-content">
          <tr>
            {tableNetwork.headers.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
          {tableData.map((innerArray, index) => (
            <tr key={index}>
              <td>
                <b>{innerArray[0]}</b>
              </td>
              <td>{innerArray[1]}</td>
              <td>{innerArray[2] + "m"}</td>
              <td>{innerArray[3] + "m"}</td>
              <td>{innerArray[4]}</td>
              <td>{innerArray[5]}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

function InspectionsTable({ loadState, tableData }) {
  return (
    <>
      <div className="table-header-container">
        <table className="table-header">
          <tr>
            {tableInspections.headers.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
          <tr style={{ visibility: "hidden" }}>
            <td>
              <b>10000</b>
            </td>
            <td>1</td>
            <td>2001</td>
          </tr>
        </table>
      </div>
      {loadState != "complete" && (
        <div
          className={loadState === "loading" ? "table-loading" : "table-empty"}
        >
          {loadState === "idle" ? (
            "Please generate a network"
          ) : (
            <>
              Generating network<span className="loading-dot-one">.</span>
              <span className="loading-dot-two">.</span>
              <span className="loading-dot-three">.</span>
            </>
          )}
        </div>
      )}
      <div className="table-content-container">
        <table className="table-content">
          <tr>
            {tableInspections.headers.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
          {tableData.map((innerArray, index) => (
            <tr key={index}>
              <td>
                <b>{innerArray[0]}</b>
              </td>
              <td>{innerArray[1]}</td>
              <td>{innerArray[2]}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default function GISTable({ dataRetrievalState, tableData }) {
  const [selectedTable, setSelectedTable] = useState("pipes");

  return (
    <div className="table-container">
      <div
        className={
          dataRetrievalState != "complete" ? "table-blur" : "table-clear"
        }
      >
        <TableOptions
          loadState={dataRetrievalState}
          setSelectedTable={setSelectedTable}
          selectedTable={selectedTable}
        />
        {selectedTable === "pipes" && (
          <PipesTable
            loadState={dataRetrievalState}
            tableData={tableData.pipes}
          />
        )}
        {selectedTable === "inspections" && (
          <InspectionsTable
            loadState={dataRetrievalState}
            tableData={tableData.inspections}
          />
        )}
      </div>
    </div>
  );
}
