import "./toggle-switch.style.scss";

export default function ToggleSwitch({ style, label, setState, state }) {
  const toggleState = () => {
    setState(!state);
  };

  return (
    <div className="toggle-container" style={style}>
      {label}
      <label className="switch">
        <input checked={state} onChange={toggleState} type="checkbox" />
        <span className="slider round"></span>
      </label>
    </div>
  );
}
