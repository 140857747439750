import { useState } from "react";
import "./info-button.style.scss";
import iconClose from "../../assets/icons-close.png";

export default function InfoButton({ showInfoButton = true, title, children }) {
  const [showInfo, setShowInfo] = useState(false);

  const closeWindow = () => {
    setShowInfo(false);
  };

  const openWindow = () => {
    setShowInfo(true);
  };

  return (
    <>
      {showInfo && (
        <>
          <div onClick={closeWindow} className="info-window-background"></div>
          <div className="info-window">
            <div className="dialogue-heading">
              <span>{title}</span>
              <button className="btn-close-dialogue" onClick={closeWindow}>
                <img src={iconClose} />
              </button>
            </div>
            <div className="dialogue-content">{children}</div>
          </div>
        </>
      )}
      <div className="info-btn">
        <span onClick={openWindow} className="btn-information">
          i
        </span>
      </div>
    </>
  );
}
