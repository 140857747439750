export default function ShowChildren({ foldState, children }) {
  return (
    <div
      style={!foldState ? { display: "none" } : { display: "block" }}
      className="show-children"
    >
      {children}
    </div>
  );
}
