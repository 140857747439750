import { useState, useEffect } from "react";

/**
 * Hook to store, update, and retrieve data in localStorage.
 *
 * @param {string} key - Key to the localStorage value
 * @param {*} initialValue - Initial value to the key
 * @returns a tuple of a value and a setter function
 *
 * @example
 * const const [graphView, setGraphView] = useLocal("p--graph-view", "focused");
 * const someVariable = graphView; // Retrive value
 * setGraphView("single") // Update value
 */
export default function useLocal(key, initialValue) {
  // Convert string stored in localStorage to typed values
  function convertType(string) {
    if (isNaN(string) === false) {
      // If string is a number
      return parseFloat(string);
    } else if (string === "true") {
      // If string is a boolean
      return true;
    } else if (string === "false") {
      return false;
    } else {
      return string;
    }
  }

  // Overide initialValue if it is already stored in localStorage
  function getInitial() {
    return localStorage.getItem(key) != null
      ? convertType(localStorage.getItem(key))
      : initialValue;
  }

  const [value, setValue] = useState(getInitial());

  // When 'value' is updated by the set function, re-save the local storage with new data
  useEffect(() => {
    localStorage.setItem(key, value.toString());
  }, [value]);

  return [value, setValue];
}
