import "./options-hub.style.scss";

/**

two possible options
 - 2 or 3 tabs
 - 1 tab

i propose
<OptionsHub>
  <ModelTab title="Types of models"/>
  <PlotTab/>
  <AnalysisTab/>
 </OptionsHub>

--> conditionally for >2 tabs render click through menu
--> else render single tab
 */

/**
 * OptionsHub component
 *
 * @component
 */
export default function OptionsHub({ children }) {
  return <div className="options-hub">{children}</div>;
}
