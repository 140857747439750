import { useState } from "react";
import "./page-title-card.style.scss";
import iconClose from "../../assets/icons-close.png";

export default function PageTitleCard({
  showInfoButton = true,
  title,
  children,
}) {
  const [showInfo, setShowInfo] = useState(false);

  const closeWindow = () => {
    setShowInfo(false);
  };

  const openWindow = () => {
    setShowInfo(true);
  };

  return (
    <>
      {showInfo && (
        <>
          <div onClick={closeWindow} className="info-window-background"></div>
          <div className="info-window">
            <div className="dialogue-heading">
              <span>{title}</span>
              <button className="btn-close-dialogue" onClick={closeWindow}>
                <img src={iconClose} />
              </button>
            </div>
          </div>
        </>
      )}
      <div
        style={
          title.toLowerCase() === "prognostics"
            ? {
                background:
                  "linear-gradient(162deg, rgb(133, 47, 47), rgb(177, 124, 124))",
              }
            : title.toLowerCase() === "gis statistics"
            ? { background: "linear-gradient(162deg, #36852f, #8ab17c)" }
            : title.toLowerCase() === "gis map" && {
                background:
                  "linear-gradient(162deg, rgb(182, 143, 51), rgb(209, 167, 68))",
              }
        }
        className="page-title-dru">
        <h1>
          {title}
          {showInfoButton && (
            <span onClick={openWindow} className="btn-information">
              i
            </span>
          )}
        </h1>
        <p>{children}</p>
      </div>
    </>
  );
}
