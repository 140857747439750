import "./export-dialogue.style.scss";
import iconClose from "../../assets/icons-close.png";
import iconExport from "../../assets/icon-download.png";
import { mergeArraysToColumns } from "../../utils/json-to-csv-export.js";
import { calculateArrays } from "../../utils/dtmc-multigraph.js";

import { useState } from "react";

function ExportSelection({ id, label, onChange, state }) {
  return (
    <>
      <label htmlFor={id}>{label}</label>
      <input onChange={onChange} checked={state} id={id} type="checkbox" />
    </>
  );
}

function SelectionHeading({ children }) {
  return (
    <>
      <span className="sub-heading">{children}</span>
      <hr />
    </>
  );
}

export default function ExportDialogue({
  selectedExport,
  graphData,
  closeExportDialogue,
}) {
  // Declare the initial state of the checkmarks
  const [checkStateDTMC, setcheckStateDTMC] = useState({
    k1: true,
    k2: true,
    k3: true,
    k4: true,
    k5: true,
    combined: true,
    headers: true,
    mean: true,
    lower: true,
    upper: true,
  });

  const [checkStateUKF, setcheckStateUKF] = useState({
    headers: true,
    filteringStage: true,
    inspections: true,
    valuePrediction: true,
    upperConfBound: true,
    lowerConfBound: true,
  });

  const [resultArray1, resultArray2, resultArray3, resultArray4, resultArray5] =
    calculateArrays(
      graphData.dtmcOne.mean,
      graphData.dtmcTwo.mean,
      graphData.dtmcThree.mean,
      graphData.dtmcFour.mean,
      graphData.dtmcFive.mean
    );

  const exportCSV = () => {
    if (typeof graphData.dtmcOne.mean[0] != "string") {
      graphData.dtmcOne.lower.unshift("k = 1 Lower");
      graphData.dtmcOne.mean.unshift("k = 1 Mean");
      graphData.dtmcOne.upper.unshift("k = 1 Upper");

      graphData.dtmcTwo.lower.unshift("k = 2 Lower");
      graphData.dtmcTwo.mean.unshift("k = 2 Mean");
      graphData.dtmcTwo.upper.unshift("k = 2 Upper");

      graphData.dtmcThree.lower.unshift("k = 3 Lower");
      graphData.dtmcThree.mean.unshift("k = 3 Mean");
      graphData.dtmcThree.upper.unshift("k = 3 Upper");

      graphData.dtmcFour.lower.unshift("k = 4 Lower");
      graphData.dtmcFour.mean.unshift("k = 4 Mean");
      graphData.dtmcFour.upper.unshift("k = 4 Upper");

      graphData.dtmcFive.lower.unshift("k = 5 Lower");
      graphData.dtmcFive.mean.unshift("k = 5 Mean");
      graphData.dtmcFive.upper.unshift("k = 5 Upper");

      resultArray1.unshift("k = 1 Combined");
      resultArray2.unshift("k = 2 Combined");
      resultArray3.unshift("k = 3 Combined");
      resultArray4.unshift("k = 4 Combined");
      resultArray5.unshift("k = 5 Combined");
    }

    if (typeof graphData.ukf.filteringStageY[0] != "string") {
      graphData.ukf.filteringStageY.unshift("Filtering stage");
      graphData.ukf.inspectionsX.unshift("Inspections");
      graphData.ukf.predictedMeanY.unshift("Expected value prediction");
      graphData.ukf.predictedLowY.unshift("Lower confidence bounds");
      graphData.ukf.predictedUpY.unshift("Upper confidence bounds");
    }

    if (!checkStateDTMC.headers) {
      graphData.dtmcOne.lower.shift();
      graphData.dtmcOne.mean.shift();
      graphData.dtmcOne.upper.shift();

      graphData.dtmcTwo.lower.shift();
      graphData.dtmcTwo.mean.shift();
      graphData.dtmcTwo.upper.shift();

      graphData.dtmcThree.lower.shift();
      graphData.dtmcThree.mean.shift();
      graphData.dtmcThree.upper.shift();

      graphData.dtmcFour.lower.shift();
      graphData.dtmcFour.mean.shift();
      graphData.dtmcFour.upper.shift();

      graphData.dtmcFive.lower.shift();
      graphData.dtmcFive.mean.shift();
      graphData.dtmcFive.upper.shift();

      resultArray1.shift();
      resultArray2.shift();
      resultArray3.shift();
      resultArray4.shift();
      resultArray5.shift();
    }

    if (!checkStateUKF.headers) {
      graphData.ukf.filteringStageY.shift();
      graphData.ukf.inspectionsX.shift();
      graphData.ukf.predictedMeanY.shift();
      graphData.ukf.predictedLowY.shift();
      graphData.ukf.predictedUpY.shift();
    }

    let dtmcCSV = mergeArraysToColumns([
      checkStateDTMC.k1 && checkStateDTMC.lower
        ? graphData.dtmcOne.lower
        : undefined,
      checkStateDTMC.k1 && checkStateDTMC.mean
        ? graphData.dtmcOne.mean
        : undefined,
      checkStateDTMC.k1 && checkStateDTMC.upper
        ? graphData.dtmcOne.upper
        : undefined,
      checkStateDTMC.k2 && checkStateDTMC.lower
        ? graphData.dtmcTwo.lower
        : undefined,
      checkStateDTMC.k2 && checkStateDTMC.mean
        ? graphData.dtmcTwo.mean
        : undefined,
      checkStateDTMC.k2 && checkStateDTMC.upper
        ? graphData.dtmcTwo.upper
        : undefined,
      checkStateDTMC.k3 && checkStateDTMC.lower
        ? graphData.dtmcThree.lower
        : undefined,
      checkStateDTMC.k3 && checkStateDTMC.mean
        ? graphData.dtmcThree.mean
        : undefined,
      checkStateDTMC.k3 && checkStateDTMC.upper
        ? graphData.dtmcThree.upper
        : undefined,
      checkStateDTMC.k4 && checkStateDTMC.lower
        ? graphData.dtmcFour.lower
        : undefined,
      checkStateDTMC.k4 && checkStateDTMC.mean
        ? graphData.dtmcFour.mean
        : undefined,
      checkStateDTMC.k4 && checkStateDTMC.upper
        ? graphData.dtmcFour.upper
        : undefined,
      checkStateDTMC.k5 && checkStateDTMC.lower
        ? graphData.dtmcFive.lower
        : undefined,
      checkStateDTMC.k5 && checkStateDTMC.mean
        ? graphData.dtmcFive.mean
        : undefined,
      checkStateDTMC.k5 && checkStateDTMC.upper
        ? graphData.dtmcFive.upper
        : undefined,
      checkStateDTMC.combined ? resultArray1 : undefined,
      checkStateDTMC.combined ? resultArray2 : undefined,
      checkStateDTMC.combined ? resultArray3 : undefined,
      checkStateDTMC.combined ? resultArray4 : undefined,
      checkStateDTMC.combined ? resultArray5 : undefined,
    ]);

    let ukfCSV = mergeArraysToColumns([
      checkStateUKF.filteringStage ? graphData.ukf.filteringStageY : undefined,
      checkStateUKF.inspections ? graphData.ukf.inspectionsX : undefined,
      checkStateUKF.valuePrediction ? graphData.ukf.predictedMeanY : undefined,
      checkStateUKF.upperConfBound ? graphData.ukf.predictedUpY : undefined,
      checkStateUKF.lowerConfBound ? graphData.ukf.predictedLowY : undefined,
    ]);

    let hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv;charset=utf-8," +
      encodeURI(
        (selectedExport === "dtmc" && dtmcCSV) ||
          (selectedExport === "ukf" && ukfCSV)
      );
    hiddenElement.target = "_blank";

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = selectedExport + "-export.csv";
    hiddenElement.click();

    // console.log(graphData.dtmcOne.mean);
  };

  const togglePropertyDTMC = (property) =>
    setcheckStateDTMC({
      ...checkStateDTMC,
      [property]: !checkStateDTMC[property],
    });

  const togglePropertyUKF = (property) =>
    setcheckStateUKF({
      ...checkStateUKF,
      [property]: !checkStateUKF[property],
    });

  return (
    <>
      <div
        className="export-dialogue-background"
        onClick={closeExportDialogue}
      ></div>
      <div className="export-dialogue">
        <div className="dialogue-heading">
          <span>Export {selectedExport.toUpperCase()} data</span>
          <button className="btn-close-dialogue" onClick={closeExportDialogue}>
            <img src={iconClose} />
          </button>
        </div>
        <div className="dialogue-options">
          <SelectionHeading>Options</SelectionHeading>

          {selectedExport === "dtmc" && (
            <>
              <div className="checkbox-row">
                <ExportSelection
                  label="Headers:"
                  onChange={() =>
                    setcheckStateDTMC({
                      ...checkStateDTMC,
                      headers: !checkStateDTMC.headers,
                    })
                  }
                  state={checkStateDTMC.headers}
                  id="export-headers"
                />
              </div>
              <SelectionHeading>Parameters</SelectionHeading>
              <div className="checkbox-row">
                <ExportSelection
                  label="k = 1:"
                  onChange={() => {
                    togglePropertyDTMC("k1");
                  }}
                  state={checkStateDTMC.k1}
                  id="export-dtmc-k1"
                />
                <ExportSelection
                  label="k = 2:"
                  onChange={() => {
                    togglePropertyDTMC("k2");
                  }}
                  state={checkStateDTMC.k2}
                  id="export-dtmc-k2"
                />
                <ExportSelection
                  label="k = 3:"
                  onChange={() => {
                    togglePropertyDTMC("k3");
                  }}
                  state={checkStateDTMC.k3}
                  id="export-dtmc-k3"
                />
                <ExportSelection
                  label="k = 4:"
                  onChange={() => {
                    togglePropertyDTMC("k4");
                  }}
                  state={checkStateDTMC.k4}
                  id="export-dtmc-k4"
                />
                <ExportSelection
                  label="k = 5:"
                  onChange={() => {
                    togglePropertyDTMC("k5");
                  }}
                  state={checkStateDTMC.k5}
                  id="export-dtmc-k5"
                />
              </div>
              <SelectionHeading>Other</SelectionHeading>

              <div className="checkbox-row">
                <ExportSelection
                  label="Mean:"
                  onChange={() => {
                    togglePropertyDTMC("mean");
                  }}
                  state={checkStateDTMC.mean}
                  id="export-dtmc-mean"
                />
                <ExportSelection
                  label="Upper bounds:"
                  onChange={() => {
                    togglePropertyDTMC("upper");
                  }}
                  state={checkStateDTMC.upper}
                  id="export-dtmc-upper"
                />
                <ExportSelection
                  label="Lower bounds:"
                  onChange={() => {
                    togglePropertyDTMC("lower");
                  }}
                  state={checkStateDTMC.lower}
                  id="export-dtmc-lower"
                />
                <ExportSelection
                  label="Combined state probability over pipe age:"
                  onChange={() => {
                    togglePropertyDTMC("combined");
                  }}
                  state={checkStateDTMC.combined}
                  id="export-dtmc-combined"
                />
              </div>
            </>
          )}
          {selectedExport === "ukf" && (
            <>
              <div className="checkbox-row">
                <ExportSelection
                  label="Headers:"
                  onChange={() =>
                    setcheckStateUKF({
                      ...checkStateUKF,
                      headers: !checkStateUKF.headers,
                    })
                  }
                  state={checkStateDTMC.headers}
                  id="export-headers"
                />
              </div>
              <SelectionHeading>Parameters</SelectionHeading>
              <div className="checkbox-row">
                <ExportSelection
                  label="Filtering stage:"
                  onChange={() => {
                    togglePropertyUKF("filteringStage");
                  }}
                  state={checkStateUKF.filteringStage}
                  id="export-ukf-filteringStage"
                />
                <ExportSelection
                  label="Inspections:"
                  onChange={() => {
                    togglePropertyUKF("inspections");
                  }}
                  state={checkStateUKF.inspections}
                  id="export-ukf-inspections"
                />
              </div>
              <div className="checkbox-row">
                <ExportSelection
                  label="Upper confidence bounds:"
                  onChange={() => {
                    togglePropertyUKF("upperConfBound");
                  }}
                  state={checkStateUKF.upperConfBound}
                  id="export-ukf-upper"
                />
                <ExportSelection
                  label="Lower confidence bounds:"
                  onChange={() => {
                    togglePropertyUKF("lowerConfBound");
                  }}
                  state={checkStateUKF.lowerConfBound}
                  id="export-ukf-lower"
                />
              </div>
            </>
          )}
        </div>
        <div className="dialogue-footer">
          <div className="btn-export-final-container">
            <button onClick={exportCSV} className="btn-export-final">
              <img src={iconExport} />
              Export
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
