import "./base-input.style.scss";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

/**
 * Component for the base inputs of the options hub.
 *
 * @component
 * @example
 * <BaseInput
 *      hasField={false}
 *      name="mpo-policy--mdp--action-1"
 *      activated={false}
 *      label="Action 1"
 * />
 *
 * @param {boolean} hasField - Indicates if the item has an input field.
 * @param {string} name - The base name of the input(s).
 * @param {boolean} markState - Indicates the state of the checkbox.
 * @param {string} label - The text displayed by the label.
 * @param {string} [initialValue=""] - Indicates the initial value of the text field.
 */

export default function BaseInput({
  main,
  hasField,
  name,
  label,
  markState,
  initialValue,
  onChange,
  setGisParameters,
  gisParameters,
}) {
  const [checked, setChecked] = useState(markState ?? false);
  const [textValue, setTextValue] = useState(initialValue);

  useEffect(() => {
    setGisParameters((prevParameters) => ({
      ...prevParameters,
      [name]: checked,
    }));
  }, [checked, name, setGisParameters]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleTextChange = (event) => {
    const newValue = event.target.value;
    setTextValue(newValue);
    // Call the onChange function with the new value
    if (onChange) {
      onChange(newValue); // Pass the new text input value to the parent component
    }
  };

  return (
    <div className={main ? "base-input-main" : "base-input"}>
      <input
        type="checkbox"
        id={name + "-checkbox"}
        name={name + "-checkbox"}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={name + "-checkbox"}>{parse(label)}</label>
      {hasField && (
        <input
          step="0.1"
          type="number"
          id={name + "-textfield"}
          name={name + "-textfield"}
          value={textValue}
          onChange={handleTextChange}
        />
      )}
    </div>
  );
}
