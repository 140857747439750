export function calculateArrays(arr1, arr2, arr3, arr4, arr5) {
  const resultArray1 = arr1.slice();
  const resultArray2 = [];
  const resultArray3 = [];
  const resultArray4 = [];
  const resultArray5 = [];

  for (let i = 0; i < arr1.length; i++) {
    resultArray2.push(arr1[i] + arr2[i]);
    resultArray3.push(resultArray2[i] + arr3[i]);
    resultArray4.push(resultArray3[i] + arr4[i]);
    resultArray5.push(resultArray4[i] + arr5[i]);
  }

  return [resultArray1, resultArray2, resultArray3, resultArray4, resultArray5];
}
