import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import OptionsTab from "../OptionsTab/options-tab.component";
import InputChildren from "../InputChildren/input-children.component";
import InputHeading from "../InputHeading/input-heading.component";
import ShowChildren from "../ShowChildren/show-children.component";
import ExportDialogue from "../ExportDialogue/export-dialogue.component";
import { calculateArrays } from "../../utils/dtmc-multigraph.js";

const ProgModelTab = forwardRef(
  (
    {
      specialState,
      cohortDamageString,
      updateGraphData,
      setFoldStateDTMC,
      foldStateDTMC,
      setFoldStateUKF,
      foldStateUKF,
      setGraphRangeDTMC,
      graphData,
      foldStateSLURF,
      setFoldStateSLURF,
      setDTMCData,
      DTMCData,
      handleSubmitDTMC,
      setIsLoadingDTMC,
      isLoadingDTMC,
      handleFreqData,
      networkGenerated
    },
    ref
  ) => {
    // Set to use a local server instance (change fetch URL)
    const localServer = false;
    // Set server URL and local instance location
    const serverURL = localServer
      ? "http://127.0.0.1:5000"
      : "https://primavera-backend-f46223d22b3e.herokuapp.com";

    // Set export dialogue initial state
    const [showExportDialogue, setShowExportDialogue] = useState(false);
    const [selectedExport, setSelectedExport] = useState("dtmc");

    const closeExportDialogue = () => {
      setShowExportDialogue(false);
    };

    const [isLoadingUKF, setIsLoadingUKF] = useState(false);
    const [isLoadingSLURF, setIsLoadingSLURF] = useState(false);

 



    // useEffect(() => {
    //   console.log(DTMCData);
    // }, [DTMCData]);

    // Session storage : Set default (on load) UKF parameters
    const getUKFData = () => {
      // Parameters upon initial page load
      const defaultState = {
        "p-ukf--in-num": 7,
        "p-ukf--ds-pred": 60,
        "p-ukf--y-thr": 20,
        "p-ukf--pr-noise": 1e-3,
        "p-ukf--toggle": true,
      };
      // Parameters retrieved from sessionStorage
      const retrievedState = JSON.parse(sessionStorage.getItem("p-ukf"));

      return retrievedState != null ? retrievedState : defaultState;
    };
    const [UKFData, setUKFData] = useState(getUKFData());

    // Upon UKF parameter change, update session storage key value
    useEffect(() => {
      sessionStorage.setItem("p-ukf", JSON.stringify(UKFData));
    }, [UKFData]);

    // Session storage : Set default (on load) SLURF parameters
    const getSLURFData = () => {
      // Parameters upon initial page load
      const defaultState = {
        "p-slurf--samples": "100",
        "p-slurf--conf": "0.99",
      };
      // Parameters retrieved from sessionStorage
      const retrievedState = JSON.parse(sessionStorage.getItem("p-slurf"));

      return retrievedState != null ? retrievedState : defaultState;
    };

    const [SLURFData, setSLURFData] = useState(getSLURFData);

    useEffect(() => {
      sessionStorage.setItem("p-slurf", JSON.stringify(SLURFData));
    }, [SLURFData]);

    // Upon page load, perform fetch requests
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Call the handleSubmit function
          if (foldStateDTMC){
            await handleRetrieveDTMC();
            await handleSubmitDTMC();
          }

          if (foldStateSLURF){
            await handleSubmitSLURF();
          }

          if (foldStateUKF){
            await handleSubmitUKF();
          }
         
          if (sessionStorage['gis--table-data'] != undefined) {
            console.log('RAN THERE')
            await handleFreqData();
          }
          
          

        } catch (error) {
          // Handle errors here
          console.error("Error:", error);
        }
      };

      fetchData();
    }, [foldStateDTMC,foldStateSLURF,foldStateUKF, specialState]);


      // // Upon page load, perform fetch requests
      // useEffect(() => {
        
      //   const fetchData = async () => {
      //     try {
      //       if (!isLoadingSLURF && !isLoadingUKF && !isLoadingDTMC) {
      //         if (sessionStorage['gis--table-data'] != undefined) {
      //           console.log('RAN HERE')
      //           await handleFreqData();
      //         }
      //       }
            

  
      //     } catch (error) {
      //       // Handle errors here
      //       console.error("Error:", error);
      //     }
      //   };
  
      //   fetchData();
      // }, [graphData]);

    // const handleRetrieveDTMC = async () => {
    //   try {
    //     setIsLoading(true);

    //     // Make the fetch request with the cohort and damage parameters
    //     const response = await fetch("https://primavera.codesystems.org" + "/dtmc-parameters/", {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ cohort_damage_string: cohortDamageString }),
    //     });

    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }

    //     const data = await response.json();

    //     // Set new DTMC parameter values
    //     setDTMCData(data);
    //     // Continue with handleSubmitDTMC
    //   } catch (error) {
    //     // Handle any errors that occur during the request
    //     console.error("Error:", error);
    //   } finally {
    //     await handleSubmitDTMC(); // Assuming handleSubmitDTMC is an asynchronous function

    //     setIsLoading(false);
    //   }
    // };
    const handleRetrieveDTMC = () => {
      setIsLoadingDTMC(true);

      // Return a promise
      return new Promise((resolve, reject) => {
        // Make the fetch request with the cohort and damage parameters
        fetch("https://primavera.codesystems.org" + "/dtmc-parameters/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ cohort_damage_string: cohortDamageString }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            console.log('DATADATADATADATADATADATA :: ', data);
            setDTMCData(data);
            resolve(data); // Resolve the promise with the data
            return data;
          })
          .then((data) => {
            handleSubmitDTMC(data);
          })
          .finally(() => {
            setIsLoadingDTMC(false);
          })
          .catch((error) => {
            // Reject the promise with the error
            reject(error);
            // Handle any errors that occur during the request
            console.error("Error:", error);
          });
      });
    };


    const handleSubmitSLURF = () => {
      // Return a promise
      return new Promise((resolve, reject) => {
        setIsLoadingSLURF(true);
        // Make the fetch request using the SLURF parameters
        fetch("https://primavera-backend-f46223d22b3e.herokuapp.com" + "/p-slurf/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            SLURFData,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Handle the data received from the server
            updateGraphData("slurf", {
              slurfData: data.slurf_data,
            });
            console.log(data.slurf_data);
            resolve(data); // Resolve the promise with the data
          })
          .finally(() => {
            setIsLoadingSLURF(false);
          })
          .catch((error) => {
            // Reject the promise with the error
            reject(error);
            // Handle any errors that occur during the request
            console.error("Error:", error);
          });
      });
    };

    const handleSubmitUKF = () => {
      // Return a promise
      return new Promise((resolve, reject) => {
        setIsLoadingUKF(true);
        // Make the fetch request using the UKF parameters
        fetch("https://primavera.codesystems.org" + "/p-ukf/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            UKFData,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            // Handle the data received from the server
            updateGraphData("ukf", {
              inspectionsX: data.inspectionsX,
              inspectionsY: data.inspectionsY,
              filteringStageY: data.filteringStageY,
              yThreshold: UKFData["p-ukf--y-thr"],
              Inspection_number: UKFData["p-ukf--in-num"],
              predictedMeanX: data.predicted_meanX,
              predictedMeanY: data.predicted_meanY,
              predictedLowX: data.predicted_lowX,
              predictedLowY: data.predicted_lowY,
              predictedUpX: data.predicted_upX,
              predictedUpY: data.predicted_upY,
              timeInspection: data.time_inspection,
              dsPrep: UKFData["p-ukf--ds-pred"],
              timeVector: data.t_vec,
              zMeasVector: data.z_meas_vec,
            });
            resolve(data); // Resolve the promise with the data
          })
          .finally(() => {
            setIsLoadingUKF(false);
          })
          .catch((error) => {
            // Reject the promise with the error
            reject(error);
            // Handle any errors that occur during the request
            console.error("Error:", error);
          });
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        handleRetrieveDTMC,
      }),
      [handleRetrieveDTMC]
    );

    return (
      <>
        {showExportDialogue && (
          <ExportDialogue
            closeExportDialogue={closeExportDialogue}
            selectedExport={selectedExport}
            graphData={graphData}
          />
        )}
        <OptionsTab>
          {/* {isLoading ? (
            <div className="load-fetch"></div>
          ) : (
            <div className="load-fetch--none"></div>
          )} */}
          <DTMC
            isLoading={isLoadingDTMC}
            setShowExportDialogue={setShowExportDialogue}
            setSelectedExport={setSelectedExport}
            setFoldState={setFoldStateDTMC}
            foldState={foldStateDTMC}
            setData={setDTMCData}
            data={DTMCData}
            handleSubmit={handleSubmitDTMC}
            infoTitle="DTMC"
          >
            <div className="top">
              <p>DTMC stands for Discrete-Time Markov chains.</p>
              <p>
                In this simple application, we have displayed the Markov chain
                with five states. The parameters on the module correspond to the
                transition probability matrix and initial probability state
                vector.{" "}
              </p>
            </div>
            <div className="bottom">
              <p className="note">
                For detailed information, please refer to the paper:
              </p>
              <p className="citation">
                <a target="_blank" href="https://arxiv.org/abs/2310.01888">
                  https://arxiv.org/abs/2310.01888
                </a>
                , Jimenez-Roa, L. A., Heskes, T., Tinga, T., Molegraaf, H. J., &
                Stoelinga, M. (2022, August). Deterioration modeling of sewer
                pipes via discrete-time Markov chains: A large-scale case study
                in the Netherlands. In 32nd European Safety and Reliability
                Conference, ESREL 2022: Understanding and Managing Risk and
                Reliability for a Sustainable Future (pp. 1299-1306).
              </p>
            </div>
          </DTMC>
          <UKF
            isLoading={isLoadingUKF}
            setSelectedExport={setSelectedExport}
            setShowExportDialogue={setShowExportDialogue}
            setFoldState={setFoldStateUKF}
            foldState={foldStateUKF}
            setData={setUKFData}
            data={UKFData}
            handleSubmit={handleSubmitUKF}
            infoTitle="UKF"
          >
            <div className="top">
              <p>UKF stands for Unscented Kalman Filter.</p>
              <p>
                This simple example displays measured crack lengths at a
                specified number of inspections, and the predicted crack
                propagation from that inspection. A probability distribution of
                the remaining useful life can be obtained by specifying
                (expected) future loading profiles, the failure threshold, and
                the amount of process noise in the crack propagation.
              </p>
            </div>
            <div className="bottom">
              <p className="note">
                For detailed information, please refer to the paper:
              </p>
              <p className="citation">
                <a
                  target="_blank"
                  href="https://doi.org/10.36001/ijphm.2021.v12i2.2943"
                >
                  https://doi.org/10.36001/ijphm.2021.v12i2.2943
                </a>
                , Keizers, L.S., Loendersloot, R. & Tinga, T. Unscented Kalman
                Filtering for Prognostics in Varying Operational and
                Environmental Conditions, International Journal of Prognostics
                and Health Management, 12(2) (pp.1-20)
              </p>
            </div>
          </UKF>
          <SLURF
            isLoading={isLoadingSLURF}
            setSelectedExport={setSelectedExport}
            setShowExportDialogue={setShowExportDialogue}
            setFoldState={setFoldStateSLURF}
            foldState={foldStateSLURF}
            setData={setSLURFData}
            data={SLURFData}
            handleSubmit={handleSubmitSLURF}
            infoTitle="SLURF"
          >
            <div className="top">
              <p>
                SLURF is a tool for uncertainty quantification in (discrete-time
                and continuous-time) Markov chains with uncertain transition
                probabilities.
              </p>
              <p>
                This example shows the uncertainty quantification for the fitted
                discrete-time Markov chains from the “DTMC” tab. The plot shows
                the cumulative probability of reaching state k=5 of the Markov
                chain over time. Each shaded region shows the probability
                (indicated by the color bar) that the failure probability over
                time for the actual (unknown) Markov chain is contained in that
                region.
              </p>
            </div>
            <div className="mid">
              <p className="highlight">
                Parameters
                <ul>
                  <li>
                    Number of samples: The number of fitted Markov chains used
                    to perform the uncertainty quantification
                  </li>
                  <li>
                    Confidence level: The probability by which the overall plot
                    is correct
                  </li>
                </ul>
              </p>
            </div>
            <div className="bottom">
              <p className="note">
                For detailed information, please refer to the paper:
              </p>
              <p className="citation">
                <a target="_blank" href="https://arxiv.org/abs/2205.08300">
                  https://arxiv.org/abs/2205.08300
                </a>
                ,Thom Badings, Sebastian Junges, Nils Jansen, Marielle
                Stoelinga, and Matthias Volk (2022). Sampling-Based Verification
                of CTMCs with Uncertain Rates. In Computed Aided Verification
                (CAV) 2022.
              </p>
            </div>
          </SLURF>
        </OptionsTab>
      </>
    );
  }
);

const DTMC = ({
  isLoading,
  data,
  setData,
  handleSubmit,
  setFoldState,
  foldState,
  setShowExportDialogue,
  setSelectedExport,
  children,
  infoTitle,
}) => {
  const [checked, setChecked] = useState(false);
  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleFoldChange = () => {
    setFoldState(!foldState);
  };

  return (
    <>
      <InputHeading
        isLoading={isLoading}
        name="dtmc"
        setShowExportDialogue={setShowExportDialogue}
        foldState={foldState}
        handleFoldChange={handleFoldChange}
        handleSubmit={handleSubmit}
        setSelectedExport={setSelectedExport}
        infoContent={children}
        infoTitle={infoTitle}
      >
        DTMC
      </InputHeading>
      <ShowChildren foldState={foldState}>
        <InputChildren
          name="dtmc"
          setData={setData}
          parent={false}
          // columns={[
          //   {
          //     totalSumEqualsTo: false,
          //     inputs: [
          //       //  {add inputs here}
          //     ],
          //   },
          //   {
          //     totalSumEqualsTo: 1,
          //     inputs: [
          //       //  {add inputs here}
          //     ],
          //   },
          // ]}
          tableColumnEqualsTo={[false, 1]}
          table={[
            [
              {
                name: "p-dtmc--k1-2",
                label: "K<sub>12</sub>",
                initialValue: data["p-dtmc--k1-2"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--k2-3",
                label: "K<sub>23</sub>",
                initialValue: data["p-dtmc--k2-3"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--k3-4",
                label: "K<sub>34</sub>",
                initialValue: data["p-dtmc--k3-4"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--k4-5",
                label: "K<sub>45</sub>",
                initialValue: data["p-dtmc--k4-5"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--range",
                label: "Range",
                initialValue: data["p-dtmc--range"],
                onChange: { handleCheckboxChange },
                min: 1,
                max: "disabled",
                step: 1,
              },
            ],
            [
              {
                name: "p-dtmc--s1",
                label: "S<sub>1</sub>",
                initialValue: data["p-dtmc--s1"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--s2",
                label: "S<sub>2</sub>",
                initialValue: data["p-dtmc--s2"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--s3",
                label: "S<sub>3</sub>",
                initialValue: data["p-dtmc--s3"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--s4",
                label: "S<sub>4</sub>",
                initialValue: data["p-dtmc--s4"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
              {
                name: "p-dtmc--s5",
                label: "S<sub>5</sub>",
                initialValue: data["p-dtmc--s5"],
                onChange: { handleCheckboxChange },
                min: 0,
                max: 1,
                step: 0.01,
              },
            ],
          ]}
        />
      </ShowChildren>
    </>
  );
};

const SLURF = ({
  isLoading,
  style,
  data,
  setData,
  handleSubmit,
  setFoldState,
  foldState,
  setShowExportDialogue,
  setSelectedExport,
  children,
  infoTitle,
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleFoldChange = () => {
    setFoldState(!foldState);
  };

  return (
    <>
      <InputHeading
        isLoading={isLoading}
        setShowExportDialogue={setShowExportDialogue}
        // wip={true}
        name="slurf"
        foldState={foldState}
        handleFoldChange={handleFoldChange}
        handleSubmit={handleSubmit}
        setSelectedExport={setSelectedExport}
        infoContent={children}
        infoTitle={infoTitle}
      >
        SLURF
      </InputHeading>

      <ShowChildren foldState={foldState}>
        <InputChildren
          name="slurf"
          setData={setData}
          parent={false}
          flat={[
            {
              name: "p-slurf--samples",
              label: "Number of samples",
              initialValue: data["p-slurf--samples"],
              onChange: { handleCheckboxChange },
              min: 1,
              max: 3000,
              step: 1,
            },
            {
              name: "p-slurf--conf",
              label: "Confidence level",
              initialValue: data["p-slurf--conf"],
              onChange: { handleCheckboxChange },
              min: 0.1,
              max: 0.99,
              step: 0.001,
            },
          ]}
        />
      </ShowChildren>
    </>
  );
};

const UKF = ({
  isLoading,
  data,
  setData,
  handleSubmit,
  setFoldState,
  foldState,
  setShowExportDialogue,
  setSelectedExport,
  children,
  infoTitle,
}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  // const handleFoldChangeNull = () => {
  //   setChecked(checked);
  // };

  const handleFoldChange = () => {
    setFoldState(!foldState);
  };

  return (
    <>
      <InputHeading
        isLoading={isLoading}
        setShowExportDialogue={setShowExportDialogue}
        // wip={true}
        name="ukf"
        foldState={foldState}
        handleFoldChange={handleFoldChange}
        handleSubmit={handleSubmit}
        setSelectedExport={setSelectedExport}
        infoContent={children}
        infoTitle={infoTitle}
      >
        UKF
      </InputHeading>

      <ShowChildren foldState={foldState}>
        <InputChildren
          name="ukf"
          setData={setData}
          parent={false}
          flat={[
            {
              name: "p-ukf--in-num",
              label: "Inspection number",
              initialValue: data["p-ukf--in-num"],
              onChange: { handleCheckboxChange },
              min: 4,
              max: 15,
              step: 1,
            },
            {
              name: "p-ukf--ds-pred",
              label: "Future stress change",
              initialValue: data["p-ukf--ds-pred"],
              onChange: { handleCheckboxChange },
              min: 20,
              max: 200,
              step: 1,
            },
            {
              name: "p-ukf--y-thr",
              label: "Failure threshold",
              initialValue: data["p-ukf--y-thr"],
              onChange: { handleCheckboxChange },
              min: 5,
              max: 21.2,
              step: 0.1,
            },
            {
              name: "p-ukf--pr-noise",
              label: "Process noise",
              initialValue: data["p-ukf--pr-noise"],
              onChange: { handleCheckboxChange },
              min: 1e-2,
              max: 1e-3,
              step: 0.001,
            },
            // {
            //   type: "boolean",
            //   name: "p-ukf--toggle",
            //   label: "Toggle",
            //   initialValue: data["p-ukf--toggle"],
            //   onChange: { handleCheckboxChange },
            // },
          ]}
        />
      </ShowChildren>
    </>
  );
};

export default ProgModelTab;
